import Box, { BoxProps } from '@mui/joy/Box';

export const FieldGroup = ({ sx, ...props }: BoxProps) => (
  <Box
    sx={[
      {
        position: 'relative',
        display: 'flex',
        gap: '20px',
        '& > div:not(.fit-content)': {
          flex: 1,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);
