import { Box, Button } from '@mui/joy';

import Avatar from '@components/Avatar';
import { useCustomAvatar } from '@components/useCustomAvatar';
import { getContactInitials } from '@features/Contacts/ContactDetail/ViewContact';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';
import { SelectAvatarModal } from './SelectAvatarModal';

export const EditContactAvatar = () => {
  const {
    inMemoryContact,
    avatar: { avatarPreview, setIsAvatarOpen },
  } = useContactsProvider();

  const [customAvatarSrc] = useCustomAvatar(inMemoryContact?.avatar);
  const avatarSrc = (() => {
    if (avatarPreview) {
      return avatarPreview;
    }

    if (inMemoryContact?.avatarSlug === 'custom') {
      return customAvatarSrc;
    }

    return `assets/avatars/${inMemoryContact?.avatarSlug ?? 'motzart'}.png`;
  })();

  if (!inMemoryContact) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            fontSize: 20,
            fontWeight: 400,
            marginRight: 1,
          }}
          src={avatarSrc}
          onClick={() => setIsAvatarOpen(true)}
        >
          {getContactInitials(inMemoryContact)}
        </Avatar>
        <Button
          sx={{ color: 'black' }}
          variant="plain"
          onClick={() => setIsAvatarOpen(true)}
        >
          Edit
        </Button>
      </Box>
      <SelectAvatarModal />
    </>
  );
};
