import { useContext } from 'react';

import { ContactsProviderContext } from './ContactsProvider';

export const useContactsProvider = () => {
  const context = useContext(ContactsProviderContext);

  if (!context) {
    throw new Error(
      'useContactsProvider has to be used within a <ContactsProvider />'
    );
  }

  return context;
};
