import { ParsedContact, RawContact } from '@shared/models/contacts';
import { addDoc, doc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import uuid4 from 'uuid4';

import { UploadAvatarInput } from '@components/UploadAvatar';
import { useUserData } from '@components/UserData/useUserData';
import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { accountsCollection } from '@models/accounts/model';
import { contactsCollection } from '@models/contacts/model';
import { storage } from '@utils/firebase';
import { serverTimestamp } from '@utils/serverTimestamp';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type InMemoryContact = Optional<RawContact, 'accountId'>;

interface UseEditContactProps {
  selectedContactId: string | null;
  currentContact: ParsedContact | undefined;
  onDone?: (nextId?: string) => void;
  onClose?: () => void;
}

export const useEditContact = ({
  selectedContactId,
  currentContact,
  onDone,
  onClose,
}: UseEditContactProps) => {
  const user = useUser();
  const { userContact } = useUserData();
  const [selectedInstruments, setSelectedInstruments] = useState<string[]>([]);
  const [showInstrumentsModal, setShowInstrumentsModal] = useState(false);
  const organizationId = useOrganizationId();
  const [inMemoryContact, setInMemoryContact] = useState<
    InMemoryContact | undefined
  >();
  const [nextAvatar, setNextAvatar] = useState<
    { file: File; extension: string } | undefined
  >();

  const availableInstruments = useMemo(
    () => [
      ...new Set([...(userContact?.instruments || []), ...selectedInstruments]),
    ],
    [selectedInstruments, userContact]
  );

  const reset = useCallback(() => {
    if (!user?.userId) {
      throw new Error('User ID is missing');
    }

    if (currentContact) {
      setInMemoryContact({
        ...currentContact,
        updatedAt: serverTimestamp(),
        updatedBy: user.userId,
      });
    } else {
      if (!organizationId) {
        throw new Error('Organization ID is missing');
      }

      setInMemoryContact({
        avatarSlug: 'mozart',
        createdAt: serverTimestamp(),
        createdBy: user.userId,
        updatedAt: serverTimestamp(),
        updatedBy: user.userId,
        firstName: '',
        lastName: '',
        organizationId,
        tags: {},
        phones: [{ number: '', type: 'mobile', isPrimary: true }],
        emails: [{ address: '', type: 'personal', isPrimary: true }],
        addresses: [
          {
            city: '',
            country: '',
            state: '',
            street1: '',
            street2: '',
            type: 'home',
            zip: '',
          },
        ],
      });
    }
  }, [currentContact, organizationId, user]);

  const [resetOnNextUpdate, setResetOnNextUpdate] = useState(false);
  const previousContactId = usePrevious(selectedContactId);
  useEffect(() => {
    if (
      (previousContactId === selectedContactId ||
        (selectedContactId && selectedContactId !== currentContact?.id)) &&
      !resetOnNextUpdate
    ) {
      return;
    }

    reset();
    setResetOnNextUpdate(false);
  }, [
    reset,
    previousContactId,
    selectedContactId,
    currentContact,
    resetOnNextUpdate,
  ]);

  const onSubmit = useCallback(async () => {
    onClose?.();
    if (!user) {
      throw new Error('User is missing');
    }

    if (!organizationId) {
      throw new Error('Organization ID is missing');
    }

    if (!userContact) {
      throw new Error('User contact not found');
    }

    if (!inMemoryContact) {
      throw new Error('In-memory contact is missing');
    }

    const createAccount = async () => {
      const accountResult = await addDoc(accountsCollection, {
        organizationId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: user.userId,
        updatedBy: user.userId,
      });

      return accountResult.id;
    };

    const linkedAccountId = currentContact?.accountId;
    const accountId = linkedAccountId || (await createAccount());

    const fileName = nextAvatar
      ? [uuid4(), nextAvatar.extension].join('.')
      : undefined;

    const uploadPromise = new Promise<void>((resolve, reject) => {
      if (!fileName || !nextAvatar) {
        return resolve();
      }

      const storageRef = ref(
        storage,
        `/organizations/${organizationId}/profiles/${fileName}`
      );
      const uploadTask = uploadBytesResumable(storageRef, nextAvatar.file);

      const deleteOld = (async () => {
        if (currentContact?.avatar) {
          await deleteObject(
            ref(
              storage,
              `/organizations/${organizationId}/profiles/${currentContact.avatar}`
            )
          );
        }
      })();

      uploadTask.on('state_changed', undefined, reject, async () => {
        await deleteOld;

        resolve();
      });
    });

    const nextContact: RawContact = {
      ...inMemoryContact,
      emails: inMemoryContact.emails?.filter((email) => email.address) || [],
      phones: inMemoryContact.phones?.filter((phone) => phone.number) || [],
      addresses:
        inMemoryContact.addresses?.filter((address) =>
          Object.entries(address).some(
            ([key, value]) => key !== 'type' && Boolean(value)
          )
        ) || [],
      accountId,
      ...(fileName && { avatar: fileName }),
    };

    if (!nextContact.firstName) {
      throw new Error('First name is missing');
    }

    if (!selectedContactId) {
      const nextId = await (async () => {
        try {
          const result = await addDoc(contactsCollection, nextContact);

          return result.id;
        } catch (rawError) {
          const errorMessage =
            rawError && typeof rawError === 'object' && 'message' in rawError
              ? String(rawError.message)
              : undefined;

          // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
          if (errorMessage?.includes('Document already exists')) {
            const errorMessageId = errorMessage.split('/').at(-1);

            if (errorMessageId) {
              console.log(`Recovering from a silly error (${errorMessageId})…`);

              return errorMessageId;
            }
          }

          console.error('Failed to add new contact', rawError);
          console.error('Error message', errorMessage);

          throw rawError;
        }
      })();
      await uploadPromise;

      onDone?.(nextId);
    } else {
      if (!selectedContactId) {
        throw new Error('Contact ID is missing');
      }

      await updateDoc(doc(contactsCollection, selectedContactId), nextContact);
      await uploadPromise;
      onDone?.();
    }

    reset();
    setResetOnNextUpdate(true);
  }, [
    nextAvatar,
    inMemoryContact,
    organizationId,
    selectedContactId,
    user,
    userContact,
    currentContact,
    reset,
    onDone,
    onClose,
  ]);

  const [isAvatarModalOpen, setIsAvatarOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string | undefined>();
  const onAvatarUpload = useCallback(
    ({ extension, file }: UploadAvatarInput) => {
      if (!inMemoryContact) {
        throw new Error('In-memory contact is missing');
      }

      setNextAvatar({ file, extension });
      setInMemoryContact({ ...inMemoryContact, avatarSlug: 'custom' });
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          const nextPreview = reader.result?.toString();

          if (!nextPreview) {
            throw new Error('No preview source');
          }

          setAvatarPreview(nextPreview);
        },
        false
      );

      reader.readAsDataURL(file);
    },
    [inMemoryContact, setNextAvatar, setAvatarPreview, setInMemoryContact]
  );

  const isValid = useMemo(() => {
    if (!inMemoryContact) {
      return false;
    }

    return Boolean(inMemoryContact.firstName && inMemoryContact.lastName);
  }, [inMemoryContact]);

  return {
    inMemoryContact,
    setInMemoryContact,
    onSubmit,
    reset,
    isValid,
    avatar: {
      nextAvatar,
      setNextAvatar,
      isAvatarModalOpen,
      setIsAvatarOpen,
      avatarPreview,
      setAvatarPreview,
      onAvatarUpload,
    },
    instruments: {
      selectedInstruments,
      setSelectedInstruments,
      availableInstruments,
      showInstrumentsModal,
      setShowInstrumentsModal,
    },
  };
};
