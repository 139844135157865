import { Box, Button, Typography, boxClasses } from '@mui/joy';

export const ContactDrawerPaymentDetails = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',

      [`& > .${boxClasses.root}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }}
  >
    <Typography level="h3">Payment details</Typography>
    <Box>
      <Typography level="title-md">Payment status</Typography>
      <Typography level="title-md">Requested</Typography>
    </Box>
    <Box>
      <Typography level="title-md">Amount</Typography>
      <Typography level="title-md">$50</Typography>
    </Box>

    <Button>Take payment</Button>
  </Box>
);
