import { createContext } from 'react';

import {
  UseContactsProviderQueryReturn,
  useContactsProviderValue,
} from './useContactsProviderValue';

export const ContactsProviderContext = createContext<
  UseContactsProviderQueryReturn | undefined
>(undefined);

interface ContactsProviderProps {
  children?:
    | React.ReactNode
    | ((value: UseContactsProviderQueryReturn) => React.ReactNode);
}

export const ContactsProvider = ({ children }: ContactsProviderProps) => {
  const value = useContactsProviderValue();

  return (
    <ContactsProviderContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ContactsProviderContext.Provider>
  );
};
