import { IonContent, IonPage } from '@ionic/react';
import { Box } from '@mui/joy';

import { ContentContainer } from '@features/Contacts/ContentContainer';

import { ContactDrawer } from './ContactDrawer';
import { ContactsHeader } from './ContactsHeader';
import { ContactsProvider } from './ContactsProvider/ContactsProvider';
import { ContactsTable } from './ContactsTable';
import { EditContactModal } from './EditContactModal';

const ContactsList = () => {
  return (
    <ContactsProvider>
      <IonPage>
        <IonContent>
          <ContentContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                paddingLeft: '20px',
              }}
            >
              <ContactsHeader />
              <ContactsTable />
              <EditContactModal />
            </Box>
          </ContentContainer>
        </IonContent>
        <ContactDrawer />
      </IonPage>
    </ContactsProvider>
  );
};

export default ContactsList;
