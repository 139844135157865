import { AvatarGroup, Box, Tooltip, Typography } from '@mui/joy';
import React, { useCallback, useMemo, useState } from 'react';
import { useSet } from 'react-use';

import { AvatarBadge } from '@components/AvatarBadge';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import { useUser } from '@features/Organization/organizationSlice';

import { EnhancedContact } from './useEnhancedContacts';

export type ColumnLabel =
  | 'Name'
  | 'Instruments'
  | 'Email'
  | 'Phone'
  | 'Connected'
  | 'Birthday'
  | 'Street'
  | 'City'
  | 'State';

export interface ContactColumn {
  label?: ColumnLabel;
  value?: (contact: EnhancedContact) => React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement>,
    contact: EnhancedContact
  ) => void;
  enabled: boolean;
}

export const useColumns = (
  setSelectedContactId: (contactId: string) => void
) => {
  const user = useUser();

  const [columnSort, setColumnSort] = useState<{
    index: number;
    direction: 'asc' | 'desc';
  }>({ index: 1, direction: 'asc' });

  const [activeColumnsSet, actions] = useSet<ColumnLabel>(
    new Set(['Name', 'Instruments', 'Email', 'Phone', 'Birthday'])
  );
  const toggleColumn = useCallback(
    (column: ColumnLabel) => actions.toggle(column),
    [actions]
  );

  const columns = useMemo(
    (): ContactColumn[] => [
      {
        // Checkbox column
        enabled: true,
        style: { minWidth: '30px' },
        onClick: () => undefined,
      },
      {
        label: 'Name',
        enabled: activeColumnsSet.has('Name'),
        value: (contact) => (
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <AvatarBadge isPrimary={contact.tags.primaryContact}>
              <ContactAvatar contact={contact} />
            </AvatarBadge>
            <Typography level="title-md">
              {getContactName(contact, user)}
            </Typography>
          </Box>
        ),
      },
      {
        label: 'Instruments',
        enabled: activeColumnsSet.has('Instruments'),
        value: (contact) => contact.instruments?.join(', '),
      },
      {
        label: 'Email',
        enabled: activeColumnsSet.has('Email'),
        value: (contact) =>
          contact.emails?.filter((email) => email.isPrimary)?.[0]?.address,
      },
      {
        label: 'Phone',
        enabled: activeColumnsSet.has('Phone'),
        value: (contact) =>
          contact.phones?.filter((phone) => phone.isPrimary)?.[0]?.number,
      },
      {
        label: 'Birthday',
        enabled: activeColumnsSet.has('Birthday'),
        value: (contact) => contact.birthday?.toDate().toLocaleDateString(),
      },
      {
        label: 'Street',
        enabled: activeColumnsSet.has('Street'),
        value: (contact) => {
          const address = contact.addresses?.[0];

          return address ? [address.street1, address.street2].join(', ') : null;
        },
      },
      {
        label: 'City',
        enabled: activeColumnsSet.has('City'),
        value: (contact) => contact.addresses?.[0]?.city,
      },
      {
        label: 'State',
        enabled: activeColumnsSet.has('State'),
        value: (contact) => contact.addresses?.[0]?.state,
      },
      {
        label: 'Connected',
        enabled: activeColumnsSet.has('Connected'),
        onClick: (event, contact) => {
          const element =
            event.target instanceof HTMLElement ? event.target : null;

          if (!element?.closest(`[data-avatar-group]`)) {
            setSelectedContactId(contact.id);
          }
        },
        value: (contact) => (
          <AvatarGroup data-avatar-group>
            {contact.relatedContacts.map((relatedContact) => (
              <Tooltip
                key={relatedContact.id}
                variant="plain"
                title={getContactName(relatedContact, user)}
              >
                <Box>
                  <ContactAvatar
                    contact={relatedContact}
                    onClick={() => setSelectedContactId(relatedContact.id)}
                  />
                </Box>
              </Tooltip>
            ))}
          </AvatarGroup>
        ),
      },
    ],
    [user, setSelectedContactId, activeColumnsSet]
  );

  const activeColumns = useMemo(
    () => columns.filter(({ enabled }) => enabled),
    [columns]
  );

  return {
    columns,
    activeColumns,
    columnSort,
    setColumnSort,
    toggleColumn,
  };
};
