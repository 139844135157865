import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos, InfoOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Input, Typography } from '@mui/joy';

import { InnerInput } from '@components/AppIntro/FloatingLabelInput';
import { ListHelperText } from '@components/ListHelperText';
import { ModalListContainer } from '@components/Modal/ModalListContainer';

import { useUserAccess } from './useUserAccess';

export const InviteUserOverview = () => {
  const {
    onDone,
    page,
    createInvite,
    inviteEmail,
    setInviteEmail,
    emailVerified,
    inviteLoading,
    emailValid,
  } = useUserAccess();

  if (!page.current) {
    return null;
  }

  return (
    <>
      <IonHeader style={{ opacity: inviteLoading ? 0.5 : 1 }}>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {inviteLoading ? (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 999,
            }}
          >
            <CircularProgress
              color="neutral"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto',
              }}
            />
          </Box>
        ) : null}
        <ModalListContainer
          sx={{
            opacity: inviteLoading ? 0.5 : 1,
            '@media (min-width: 747px)': {
              gap: '50px',
              '& .scrollable > *': {
                margin: '0 40px',
              },
            },
          }}
        >
          <Box>
            <Typography level="h3">Invite User</Typography>
            <Typography>
              Inviting a user will allow them login and access the app.
            </Typography>
          </Box>
          <Box
            className="scrollable"
            sx={{
              alignSelf: 'center',
              width: '100%',
              maxWidth: '520px',
            }}
          >
            <Input
              color="neutral"
              slots={{ input: InnerInput }}
              slotProps={{
                input: {
                  placeholder: '',
                  type: 'text',
                  name: 'primaryEmail',
                  labelText: 'Primary Email',
                },
              }}
              value={inviteEmail}
              onChange={(event) => setInviteEmail(event.target.value)}
            />
            <ListHelperText
              sx={{
                textAlign: 'left',
                alignItems: 'center',
                padding: '6px 8px',
              }}
            >
              This email will be used as the primary email for this contact.
              <InfoOutlined sx={{ marginBottom: '-3px', fontSize: '14px' }} />
            </ListHelperText>

            {!emailVerified ? (
              <Box
                sx={{
                  margin: '0 5px',
                  padding: '10px 15px',
                  border: '1px solid red',
                  borderRadius: '8px',
                  lineHeight: 1.5,
                }}
              >
                <span style={{ color: 'red', fontSize: '20px' }}>⚠️</span>{' '}
                Please validate your email to enable this feature.
              </Box>
            ) : null}
          </Box>

          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            disabled={!emailValid || !emailVerified || inviteLoading}
            onClick={createInvite}
          >
            Send Invite
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
