import { Box, Typography } from '@mui/joy';

import { AvatarBadge } from '@components/AvatarBadge';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import { ContactTags } from '@features/Contacts/ContactDetail/ViewContact/ContactTags';
import { useUser } from '@features/Organization/organizationSlice';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const ContactDrawerHeader = () => {
  const user = useUser();
  const { currentContact } = useContactsProvider();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      <AvatarBadge isPrimary={currentContact?.tags.primaryContact}>
        <ContactAvatar contact={currentContact} />
      </AvatarBadge>
      <Box>
        <Typography level="h2">
          {getContactName(currentContact, user)}
        </Typography>
        {currentContact &&
        Object.values(currentContact?.tags || {}).some(Boolean) ? (
          <Box>
            <ContactTags sx={{ fontWeight: 500 }} contact={currentContact} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
