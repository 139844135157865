import { Box, Button, Typography } from '@mui/joy';

import { AvatarBadge } from '@components/AvatarBadge';
import { Add } from '@components/icons/Add';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const ConnectedContacts = () => {
  const { setSelectedContactId, connectedContacts } = useContactsProvider();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
      }}
    >
      <Typography variant="label">Connected Contacts</Typography>

      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          borderRadius: '5px',
          maxWidth: '100%',
          gap: '20px',
        }}
      >
        {connectedContacts?.map((linkedContact) => (
          <Button
            key={linkedContact.id}
            variant="verticalPlain"
            onClick={() => setSelectedContactId(linkedContact.id)}
            startDecorator={
              <AvatarBadge isPrimary={linkedContact.tags.primaryContact}>
                <ContactAvatar
                  contact={linkedContact}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
              </AvatarBadge>
            }
          >
            {linkedContact.firstName}
          </Button>
        ))}

        <Button variant="verticalOutline" startDecorator={<Add />}>
          Add
        </Button>
      </Box>
    </Box>
  );
};
