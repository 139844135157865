import { MoreHoriz } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  listSubheaderClasses,
} from '@mui/joy';
import { Popover } from '@mui/material';
import { useState } from 'react';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { DeleteContactConfirmationModal } from './DeleteContactConfirmationModal';

export const ContactDrawerMenu = () => {
  const {
    ref: buttonRef,
    filterIsOpen,
    setFilterIsOpen,
    anchorPosition,
  } = useFilterPopover();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <>
      <IconButton ref={buttonRef} onClick={() => setFilterIsOpen(true)}>
        <MoreHoriz />
      </IconButton>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <List
          data-event-sidebar-menu
          data-prevent-close
          size="sm"
          sx={(theme) => ({
            padding: '15px',
            [`& .${listSubheaderClasses.root}`]: {
              color: theme.palette.neutral[400],
            },
          })}
        >
          <ListItem nested>
            <ListSubheader sticky>Calendar</ListSubheader>
            <List>
              <ListItem>
                <ListItemButton variant="plain">Schedule Lesson</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
          <ListItem nested>
            <ListSubheader sticky>Payments</ListSubheader>
            <List>
              <ListItem>
                <ListItemButton variant="plain">Take a Payment</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">
                  Add card on file
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">View Payments</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
          <ListItem nested>
            <ListSubheader sticky>Manage Profile</ListSubheader>
            <List>
              <ListItem>
                <ListItemButton variant="plain">Edit</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">
                  Merge with another customer
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">Invite User</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">
                  Transfer primary contact
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton variant="plain">
                  Move Connected Contacts
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  variant="plain"
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                    setFilterIsOpen(false);
                  }}
                >
                  Delete Contact
                </ListItemButton>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Popover>
      <DeleteContactConfirmationModal
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
      />
    </>
  );
};
