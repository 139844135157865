import { Add as MuiAdd } from '@mui/icons-material';
import { Box } from '@mui/joy';

export const Add = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '14px',
      height: '14px',
      overflow: 'hidden',
    }}
  >
    <MuiAdd />
  </Box>
);
