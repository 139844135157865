import { useMemo, useState } from 'react';

import { useContactsQuery } from '@models/contacts/useContactsQuery';

import { useColumns } from './useColumns';
import { useEditContact } from './useEditContact';
import { useEnhancedContacts } from './useEnhancedContacts';
import { useRouterState } from './useRouterState';

export const useContactsProviderValue = () => {
  const [selectedContactId, setSelectedContactId] = useRouterState({
    route: 'contacts2',
    search: 'c',
  });

  const [editContactModalOpen, setEditContactModalOpen] = useState(false);
  const [contacts] = useContactsQuery();
  const currentContact = useMemo(
    () => contacts?.find((contact) => contact.id === selectedContactId),
    [contacts, selectedContactId]
  );

  const primaryContact = useMemo(
    () =>
      currentContact
        ? contacts?.find(
            (contact) => contact.accountId === currentContact.accountId
          )
        : undefined,
    [contacts, currentContact]
  );
  const connectedContacts = useMemo(() => {
    const base = contacts?.filter(
      (contact) =>
        contact.id !== selectedContactId &&
        contact.accountId === currentContact?.accountId
    );

    const primary = base?.find((contact) => contact.tags?.primaryContact);

    if (!base || !primary) {
      return base;
    }

    return base.sort((a, b) => {
      if (a.id === primary.id) {
        return -1;
      }

      if (b.id === primary.id) {
        return 1;
      }

      return 0;
    });
  }, [contacts, currentContact, selectedContactId]);

  return {
    contacts,
    editContactModalOpen,
    setEditContactModalOpen,
    selectedContactId,
    setSelectedContactId,
    currentContact,
    primaryContact,
    connectedContacts,
    enhancedContacts: useEnhancedContacts(contacts),
    ...useColumns(setSelectedContactId),
    ...useEditContact({
      currentContact,
      selectedContactId,
      onClose: () => setEditContactModalOpen(false),
      onDone: (nextId) => {
        if (nextId) {
          setSelectedContactId(nextId);
        }
      },
    }),
  };
};

export type UseContactsProviderQueryReturn = ReturnType<
  typeof useContactsProviderValue
>;
