import useHotkeys from '@reecelucas/react-use-hotkeys';
import { useState } from 'react';

import Sidebar from '@components/Sidebar';

import { ContentContainerShell } from './ContentContainer/ContentContainerShell';

interface ContentContainerProps {
  children?: React.ReactNode;
}

export const ContentContainer = ({ children }: ContentContainerProps) => {
  const [showSidebar, setShowSidebar] = useState(true);
  useHotkeys('[', async () => {
    setShowSidebar(!showSidebar);
  });

  return (
    <ContentContainerShell
      showSidebar={showSidebar}
      setShowSidebar={setShowSidebar}
      sidebar={
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      }
    >
      {children}
    </ContentContainerShell>
  );
};
