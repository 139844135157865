import { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import { Frequency, RRule, RRuleSet } from 'rrule';

import { ParsedEvent, RawEvent } from './model';

interface GetRrulePatternResult {
  freq: Frequency | undefined;
  interval: number | undefined;
}

export const getRrulePattern = (
  frequencyOption: FrequencyOption
): GetRrulePatternResult | undefined => {
  if (frequencyOption === 'Never') {
    return undefined;
  }

  if (frequencyOption === 'Every day') {
    return {
      freq: Frequency.DAILY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every week') {
    return {
      freq: Frequency.WEEKLY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every 2 weeks') {
    return {
      freq: Frequency.WEEKLY,
      interval: 2,
    };
  }

  if (frequencyOption === 'Every month') {
    return {
      freq: Frequency.MONTHLY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every year') {
    return {
      freq: Frequency.YEARLY,
      interval: 1,
    };
  }

  throw new Error(`Invalid frequency option: ${frequencyOption}`);
};

export const frequencyOptions = [
  'Never',
  'Every day',
  'Every week',
  'Every 2 weeks',
  'Every month',
  'Every year',
] as const;

export type FrequencyOption = (typeof frequencyOptions)[number];

export const toUtcDate = (date: Date): Date =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );

export const fromUtcDate = (date: Date): Date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );

const createRrule = (event: RawEvent): RRuleSet | undefined => {
  if (!event?.ongoing || !event.frequency) {
    return undefined;
  }

  const rruleSet = new RRuleSet();

  const frequencyOption = event.frequency;
  const rrule = new RRule({
    ...getRrulePattern(frequencyOption),
    dtstart: toUtcDate(event.start.toDate()),
    ...(event.end && { until: toUtcDate(event.end.toDate()) }),
  });

  event.exceptions?.forEach((exception) => {
    rruleSet.exdate(exception.toDate());
  });

  rruleSet.rrule(rrule);

  return rruleSet;
};

interface GetRrulePatternProps {
  start: Date;
  end: Date;
  rrule: RRule;
}

export const getDatesBetween = ({ start, end, rrule }: GetRrulePatternProps) =>
  rrule.between(toUtcDate(start), toUtcDate(end)).map(fromUtcDate);

export const parseEvent = (
  doc: QueryDocumentSnapshot<RawEvent> | DocumentSnapshot<RawEvent>
): ParsedEvent | undefined => {
  const base = doc.data();

  if (!base) {
    return undefined;
  }

  const { start, end, type, ...data } = base;
  const parsedRrule = createRrule(base);

  return {
    id: doc.id,
    start: start.toDate(),
    end: end?.toDate() || null,
    type,
    ...(parsedRrule && { rrule: parsedRrule }),
    ...data,
  };
};
