import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Checkbox, Typography, boxClasses } from '@mui/joy';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';
import { ColumnCustomization } from './ColumnCustomization';

const verticalPadding = '12px';
const horizontalPadding = '3px';
const cellSpacing = '10px';
const rowHeight = 65;

export const ContactsTable = () => {
  const {
    selectedContactId,
    setSelectedContactId,
    columnSort,
    setColumnSort,
    activeColumns,
    enhancedContacts,
  } = useContactsProvider();

  return (
    <Box sx={{ marginRight: '30px' }}>
      <Box
        sx={{
          padding: `${verticalPadding} ${horizontalPadding}`,
          display: 'flex',
          borderTop: '1px solid #E0E0E0',
          borderBottom: '2px solid #E0E0E0',
          maxHeight: '51px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        {activeColumns.map((column, columnIndex) => (
          <Box
            key={columnIndex}
            sx={{
              display: 'flex',
              alignItems: 'center',
              ...(column.style ? { ...column.style } : { flex: '1 1 100%' }),
              padding: `0 ${cellSpacing}`,
            }}
          >
            {columnIndex === 0 ? (
              <Checkbox />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setColumnSort({
                    index: columnIndex,
                    direction:
                      columnSort.index === columnIndex
                        ? columnSort.direction === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc',
                  })
                }
              >
                <Typography level="title-md">{column.label}</Typography>
                <ArrowForwardIos
                  sx={{
                    transition: 'transform 0.25s, opacity 0.25s',
                    transform: `rotate(${
                      columnSort.direction === 'asc' ? 90 : -90
                    }deg)`,
                    color: 'gray',
                    fontSize: '14px',
                    opacity: columnSort.index === columnIndex ? 1 : 0,
                  }}
                />
              </Box>
            )}
          </Box>
        ))}

        <ColumnCustomization />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 190px)',
        }}
      >
        {enhancedContacts
          ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((contact) => (
            <Box
              data-is-contact-row
              key={contact.id}
              sx={{
                display: 'flex',
                padding: `0 ${horizontalPadding}`,
                transition: 'background-color 0.2s',
                borderBottom: '1px solid #E0E0E0',
                alignItems: 'center',

                [`&:hover > .${boxClasses.root}`]: {
                  backgroundColor: 'rgb(15 72 186 / 5%)',
                },
                [`& > .${boxClasses.root}.selected-row`]: {
                  backgroundColor: 'rgb(15 72 186 / 10%)',
                },
              }}
            >
              {activeColumns.map((column, columnIndex) => {
                const value = column.value?.(contact);

                return (
                  <Box
                    key={columnIndex}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: `${verticalPadding} ${cellSpacing}`,
                      ...(columnIndex === 0 ? {} : { flex: '1 1 100%' }),
                      ...(column.style
                        ? { ...column.style }
                        : { flex: '1 1 100%' }),
                      cursor: 'pointer',
                      height: `${rowHeight}px`,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    className={
                      contact.id === selectedContactId
                        ? 'selected-row'
                        : undefined
                    }
                    onClick={(event) =>
                      column.onClick
                        ? column.onClick(event, contact)
                        : setSelectedContactId(contact.id)
                    }
                  >
                    {columnIndex === 0 ? (
                      <Checkbox />
                    ) : typeof value === 'string' ? (
                      <Typography color="neutral">{value}</Typography>
                    ) : (
                      value
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
