import { IonRouterLink } from '@ionic/react';
import { Tooltip } from '@mui/joy';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { ReactNode } from 'react';
import { useRouteMatch } from 'react-router';

import {
  TooltipHotkeyTitle,
  TooltipHotkeyTitleProps,
} from './TooltipHotkeyTitle';

export interface SidebarLinkProps extends TooltipHotkeyTitleProps {
  href: string;
  children: ReactNode;
  selected?: string[];
  exactSelection?: boolean;
}

export const SidebarLink = ({
  href,
  title,
  first,
  second,
  children,
  selected: selectedPaths = [],
  exactSelection,
}: SidebarLinkProps) => {
  const match = useRouteMatch({
    path: [href, ...selectedPaths],
    exact: exactSelection,
  });
  const selected = Boolean(match);

  return (
    <IonRouterLink routerLink={href} routerDirection="none">
      <Tooltip
        title={
          first ? (
            <TooltipHotkeyTitle title={title} first={first} second={second} />
          ) : (
            <></>
          )
        }
        variant="plain"
        placement="right"
        enterDelay={700}
        hidden={!first}
      >
        <ListItem sx={{ margin: '2px 1em' }}>
          <ListItemButton
            selected={selected}
            color="neutral"
            variant="plain"
            sx={(theme) => ({
              ...(selected
                ? theme.typography['title-md']
                : theme.typography['body-md']),

              '&.Mui-selected': {
                backgroundColor: 'transparent',
                ...theme.typography['title-md'],
                fontWeight: 800,
              },
            })}
          >
            {!children ? null : (
              <ListItemDecorator sx={{ color: 'inherit' }}>
                {children}
              </ListItemDecorator>
            )}
            <ListItemContent>{title}</ListItemContent>
          </ListItemButton>
        </ListItem>
      </Tooltip>
    </IonRouterLink>
  );
};
