import { Add } from '@mui/icons-material';
import { Box } from '@mui/joy';
import { useEffect, useMemo, useState } from 'react';
import { useMeasure, usePrevious } from 'react-use';

import {
  Tag,
  TagProps,
} from '@features/Contacts/ContactDetail/ViewContact/ContactTag';

export type InstrumentTagProps = TagProps & {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const offset = 23;
const diff = 32;

export const InstrumentTag = ({
  checked,
  onChange,
  children,
  sx,
  ...props
}: InstrumentTagProps) => {
  const [ref, { width }] = useMeasure<HTMLSpanElement>();
  const [baseWidth, setBaseWidth] = useState<number | null>(null);
  const currentWidth = useMemo(
    () => (baseWidth ? baseWidth + (checked ? 3 : offset) : null),
    [baseWidth, checked]
  );

  useEffect(() => {
    if (baseWidth) {
      return;
    }

    const nextWidth = checked ? width : width - offset;

    if (nextWidth > 0) {
      setBaseWidth(nextWidth + diff);
    }
  }, [checked, width, baseWidth]);

  const [animating, setAnimating] = useState(false);
  const previousChecked = usePrevious(checked);

  useEffect(() => {
    if (!checked || previousChecked === checked) {
      return;
    }

    setAnimating(true);

    const timeoutId = setTimeout(() => {
      setAnimating(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [checked, currentWidth, previousChecked]);

  return (
    <Tag
      ref={ref}
      sx={[
        {
          cursor: 'pointer',
          transition: 'background 0.2s, max-width 0.4s',
          border: '1px solid black',
          padding: '3px 15px',
          ...(checked &&
            animating && { width: (baseWidth || 0) + diff + offset }),
          maxWidth: currentWidth,
        },
        checked
          ? { background: 'black', color: 'white' }
          : { background: 'white', color: 'black' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={{
          width: '20px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            fontSize: '16px',
          },
          transition: 'max-width 0.4s, opacity 0.5s',
          maxWidth: checked ? 0 : 20,
          opacity: checked ? 0 : 1,
        }}
      >
        <Add sx={{ color: 'black' }} />
      </Box>
      {children}
    </Tag>
  );
};
