import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';

import { MoveContactsSelection } from './MoveContactsSelection';
import { useMoveContacts } from './useMoveContacts';

export const MoveContactsOverview = () => {
  const { onDone } = useMoveContacts();

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer>
          <ModalTypographyContainer>
            <Typography level="h3">Move Connected Contacts</Typography>
            <Typography>
              Connecting contacts to this group will disconnect them from their
              current group.
            </Typography>
          </ModalTypographyContainer>
          <Box className="scrollable" />
          <Button
            sx={{ marginTop: 'auto' }}
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (event.target instanceof HTMLElement) {
                event.target
                  .closest('ion-nav')
                  ?.push(() => <MoveContactsSelection />);
              }
            }}
          >
            Move Contacts
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
