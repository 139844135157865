import { useIonRouter } from '@ionic/react';
import { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import { useSearchParam } from '@features/Contacts/useSearchParam';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

interface UseRouterStateProps {
  search: string;
  route: string;
}

export const useRouterState = ({ route, search }: UseRouterStateProps) => {
  const router = useIonRouter();
  const organizationSlug = useOrganizationSlug();
  const match = useRouteMatch(`/:organizationSlug/${route}`);
  const value = useSearchParam(search, !match);
  const setValue = useCallback(
    (next: string | undefined) => {
      const queryParam = next ? `?${search}=${next}` : '';
      router.push(`/${organizationSlug}/${route}${queryParam}`, 'none');
    },
    [organizationSlug, router, route, search]
  );

  return [value, setValue] as const;
};
