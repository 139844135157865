import { Close } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
} from '@mui/joy';

import { ListHeader } from '@components/ListHeader';
import { UploadAvatar } from '@components/UploadAvatar';
import { avatarCategories } from '@features/Contacts/ContactDetail/ViewContact/avatarCategories';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const SelectAvatarModal = () => {
  const {
    avatar: {
      isAvatarModalOpen,
      setIsAvatarOpen,
      avatarPreview,
      onAvatarUpload,
    },
    inMemoryContact,
    setInMemoryContact,
  } = useContactsProvider();

  if (!inMemoryContact) {
    return null;
  }

  return (
    <Modal open={isAvatarModalOpen} onClose={() => setIsAvatarOpen(false)}>
      <ModalDialog sx={{ backgroundColor: 'white' }} data-prevent-close>
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            left: '10px',
          }}
          onClick={() => setIsAvatarOpen(false)}
        >
          <Close />
        </IconButton>
        <Box sx={{ textAlign: 'center' }}>
          <Typography level="h3">Select Avatar</Typography>
          <Typography>Select the avatar for this contact.</Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              margin: '0 !important',
            }}
          >
            <Box>
              <UploadAvatar
                avatar={inMemoryContact.avatar}
                onAvatarUpload={onAvatarUpload}
                avatarPreview={avatarPreview}
              />
            </Box>
            {/* add custom profile image + history */}
            {Object.entries(avatarCategories).map(([category, artists]) => (
              <Box key={category}>
                <ListHeader
                  v2
                  noSideSpacing
                  sx={{ textAlign: 'left', paddingLeft: '28px' }}
                >
                  {category}
                </ListHeader>
                <Box
                  sx={{
                    padding: '5px 30px',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-start',
                    overflowX: 'auto',
                  }}
                >
                  {artists.map(({ shortName, fileName }) => {
                    const resolvedFileName =
                      fileName ?? shortName.toLowerCase().split(' ').join('-');

                    return (
                      <Button
                        key={shortName}
                        variant="verticalPlain"
                        sx={{ fontSize: '11px', marginBottom: '5px' }}
                        slotProps={{
                          startDecorator: {
                            sx: (theme) => ({
                              border: `2px solid ${
                                inMemoryContact.avatarSlug === resolvedFileName
                                  ? `rgb(${theme.palette.primary.mainChannel})`
                                  : theme.palette.common.white
                              }`,
                            }),
                          },
                        }}
                        startDecorator={
                          <Avatar
                            src={`assets/avatars/${resolvedFileName}.png`}
                            style={{
                              borderRadius: '50%',
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        }
                        onClick={() =>
                          setInMemoryContact({
                            ...inMemoryContact,
                            avatarSlug: resolvedFileName,
                          })
                        }
                      >
                        {shortName}
                      </Button>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
