import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from 'react-use';

export type UseQueryParam = (
  param: string,
  skipUpdate?: boolean
) => string | null;

const getValue = (search: string, param: string) =>
  new URLSearchParams(search).get(param);

export const useSearchParam: UseQueryParam = (param, skip) => {
  const windowLocation = window.location;
  const [, setValue] = useState<string | null>(() =>
    getValue(windowLocation.search, param)
  );

  const location = useLocation();
  const locationString = useMemo(() => JSON.stringify(location), [location]);
  const previousLocationString = usePrevious(locationString);

  useEffect(() => {
    if (locationString === previousLocationString || skip) {
      return;
    }

    setValue(getValue(windowLocation.search, param));
  }, [
    locationString,
    previousLocationString,
    param,
    windowLocation.search,
    skip,
  ]);

  const current = useMemo(
    () => getValue(windowLocation.search, param),
    [windowLocation.search, param]
  );

  return current;
};
