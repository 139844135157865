import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { RawContact } from '@shared/models/contacts';
import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';
import { useUser } from '@features/Organization/organizationSlice';
import { contactsCollection } from '@models/contacts/model';
import { useMutation } from '@models/mutations/useMutation';
import { serverTimestamp } from '@utils/serverTimestamp';

import { getContactName } from '..';
import { ContactTags } from '../ContactTags';
import { useTransferPrimary } from './useTransferPrimary';

export const TransferPrimarySelection = () => {
  const user = useUser();
  const [searchValue, setSearchValue] = useState('');

  const [removeInviteMutation] = useMutation('removeInvite');
  const {
    selected,
    setSelected,
    contactId,
    contacts,
    onDone,
    page,
    existingPrimaryContact,
  } = useTransferPrimary();

  if (!page.current) {
    return null;
  }

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer sx={{ gap: '15px' }}>
          <Box>
            <Typography level="h3">Select Contact</Typography>
            <Typography>
              Select the contact that will be the new primary contact.
            </Typography>
          </Box>

          {(contacts?.length || 0) > 6 ? (
            <Input
              variant="search"
              placeholder="Find a contact…"
              startDecorator={<Search />}
              value={searchValue}
              onChange={(event) =>
                setSearchValue(event.target.value.toLowerCase())
              }
            />
          ) : null}

          <Box className="scrollable">
            <List variant="contactList">
              {contacts
                ?.filter((contact) => contact.id !== contactId)
                .map((contact) => (
                  <Box key={contact.id}>
                    <ListItem onClick={() => setSelected(contact.id)}>
                      <ListItemButton>
                        <ListItemDecorator>
                          <ContactAvatar contact={contact} />
                        </ListItemDecorator>

                        <ListItemContent>
                          <Typography>
                            {getContactName(contact, user)}
                          </Typography>
                          <ContactTags contact={contact} />
                        </ListItemContent>
                        <Checkbox checked={selected === contact.id} />
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                  </Box>
                ))}
            </List>
          </Box>

          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            disabled={selected === undefined}
            onClick={async () => {
              if (!user) {
                throw new Error('User not found');
              }

              const existingNextPrimaryContact = contacts?.find(
                (contact) => contact.id === selected
              );

              if (!existingPrimaryContact || !existingNextPrimaryContact) {
                throw new Error('Contact not found');
              }

              if (existingPrimaryContact.userId) {
                await removeInviteMutation({
                  contactId: existingPrimaryContact.id,
                });
              }

              const previousPrimaryContact: Partial<RawContact> = {
                updatedAt: serverTimestamp(),
                updatedBy: user.userId,
                tags: {
                  ...existingPrimaryContact.tags,
                  primaryContact: false,
                },
              };

              const nextPrimaryContact: Partial<RawContact> = {
                updatedAt: serverTimestamp(),
                updatedBy: user.userId,
                tags: {
                  ...existingNextPrimaryContact.tags,
                  primaryContact: true,
                },
              };

              await Promise.all([
                updateDoc(
                  doc(contactsCollection, contactId),
                  previousPrimaryContact
                ),
                updateDoc(
                  doc(contactsCollection, selected),
                  nextPrimaryContact
                ),
              ]);

              onDone?.();
            }}
            data-testid="transfer-primary-modal"
          >
            Transfer primary contact
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
