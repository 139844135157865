import { Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Modal, Typography } from '@mui/joy';
import { deleteDoc, doc } from 'firebase/firestore';

import { contactsCollection } from '@models/contacts/model';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

interface DeleteConfirmationModalProps {
  open?: boolean;
  onClose?: () => void;
}

export const DeleteContactConfirmationModal = ({
  open,
  onClose,
}: DeleteConfirmationModalProps) => {
  const { selectedContactId } = useContactsProvider();

  if (!selectedContactId) {
    return null;
  }

  return (
    <Modal
      open={Boolean(open)}
      onClose={onClose}
      data-testid="delete-contact-confirmation-modal"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        data-testid="delete-confirmation-modal"
        sx={{
          background: 'white',
          width: '500px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          position: 'relative',
          margin: 'auto',
        }}
      >
        <Box>
          <IconButton
            sx={{
              position: 'absolute',
              top: '10px',
              left: '10px',
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>

          <Typography
            level="h3"
            sx={{
              position: 'absolute',
              top: '12px',
              left: 0,
              right: 0,
              width: 'fit-content',
              margin: 'auto',
            }}
          >
            Delete Contact
          </Typography>
        </Box>

        <Divider
          sx={{
            marginTop: '35px',
          }}
        />

        <Box
          sx={{
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography>Are you sure you want to delete this contact?</Typography>
        </Box>

        <Divider sx={{ margin: '4px 0' }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            padding: '0 20px 20px 20px',
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Back to Editing
          </Button>
          <Button
            onClick={() => {
              deleteDoc(doc(contactsCollection, selectedContactId));
              onClose?.();
            }}
            color="danger"
          >
            Delete Contact
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
