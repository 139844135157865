import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/joy';
import { Slide } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';

export interface FullScreenModalProps {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  right?: React.ReactNode;
  testId?: string;
}

export const FullScreenModal = ({
  title,
  children,
  open,
  onClose,
  right,
  testId,
}: FullScreenModalProps) =>
  createPortal(
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
        {...(testId && { 'data-testid': testId })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '15px 30px',
            position: 'relative',
          }}
        >
          <IconButton size="lg" onClick={onClose}>
            <Close />
          </IconButton>

          {title ? (
            <Typography
              level="h2"
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
                width: 'fit-content',
              }}
            >
              {title}
            </Typography>
          ) : null}

          <Box>{right}</Box>
        </Box>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>{children}</Box>
      </Box>
    </Slide>,
    document.body
  );
