import { IonRouterLink } from '@ionic/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';

interface LessonHistoryProps {
  size?: 'sm' | 'md' | 'lg';
  hideNewLessonButton?: boolean;
}

export const LessonHistory = ({
  size = 'md',
  hideNewLessonButton,
}: LessonHistoryProps) => (
  <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        paddingBottom: '20px',
        position: 'relative',
        fontWeight: 400,
        [`& .appointment-container`]: {
          position: 'relative',
          padding: '10px 0',
          borderBottom: '1px solid #E0E0E0',
          cursor: 'pointer',

          '&:first-of-type': {
            borderTop: '1px solid #E0E0E0',
          },

          '& .MuiSvgIcon-root': {
            position: 'absolute',
            right: '5px',
            top: 0,
            bottom: 0,
            margin: 'auto',
            fontSize: size ? '15px' : '18px',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography level={size === 'sm' ? 'title-lg' : 'h4'}>
          Upcoming lessons
        </Typography>
        {hideNewLessonButton ? null : (
          <Button variant="plain" color="primary">
            New lesson
          </Button>
        )}
      </Box>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Thu, Aug 14, 2031, 9:45 am</Typography>
        <ArrowForwardIos />
      </Box>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Wed, Sep 25, 2024, 12:45 pm</Typography>
        <ArrowForwardIos />
      </Box>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Thu, Sep 19, 2024, 11:00 am</Typography>
        <ArrowForwardIos />
      </Box>

      <Button
        variant="plain"
        className="sx"
        sx={{
          position: 'absolute',
          bottom: '-15px',
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      >
        Show more
      </Button>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        paddingBottom: '20px',
        textDecoration: 'none',

        [`& .appointment-container`]: {
          position: 'relative',
          padding: '10px 0',
          borderBottom: '1px solid #E0E0E0',
          cursor: 'pointer',

          '&:first-of-type': {
            borderTop: '1px solid #E0E0E0',
          },

          '& .MuiSvgIcon-root': {
            position: 'absolute',
            right: '5px',
            top: 0,
            bottom: 0,
            margin: 'auto',
            fontSize: size ? '15px' : '18px',
          },
        },
      }}
    >
      <Typography level={size === 'sm' ? 'title-lg' : 'h4'}>
        Past lessons
      </Typography>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Thu, Aug 14, 2031, 9:45 am</Typography>
        <ArrowForwardIos />
      </Box>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Wed, Sep 25, 2024, 12:45 pm</Typography>
        <ArrowForwardIos />
      </Box>

      <Box
        component={IonRouterLink}
        className="appointment-container"
        routerLink="/test/schedule?e=t31jDwlHKDgmgBysTNaA&t=1727976600000"
        routerDirection="none"
      >
        <Typography level="title-md">Piano lessons</Typography>
        <Typography>Thu, Sep 19, 2024, 11:00 am</Typography>
        <ArrowForwardIos />
      </Box>

      <Button variant="plain" sx={{ marginTop: '-12px' }}>
        Show more
      </Button>
    </Box>
  </>
);
