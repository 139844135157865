import { Box } from '@mui/joy';
import { lazy, useMemo } from 'react';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import { AppPageList } from '@components/Sidebar/GenericSidebar';

const ProfileSettings = lazy(
  () => import('@features/Settings2/ProfileSettings')
);
const BillingStrategySettings = lazy(
  () => import('@features/Settings2/BillingStrategySettings/index')
);
const CancellationSettings = lazy(
  () => import('@features/Settings2/CancellationSettings/index')
);
const ServiceSettings = lazy(
  () => import('@features/Settings2/ServiceSettings')
);
const GeneralSettings = lazy(
  () => import('@features/Settings2/GeneralSettings')
);

export const settingsAppPageLists: AppPageList[] = [
  {
    title: 'Account',
    items: [
      {
        title: 'View profile',
        url: `/settings/profile`,
        component: ProfileSettings,
        // MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Notifications',
        url: `/settings/notifications`,
        component: () => <Box>Notifications settings</Box>,
        // MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Preferences',
        url: `/settings/preferences`,
        component: () => <Box>Preferences settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
    ],
  },
  {
    title: 'Scheduling',
    items: [
      {
        title: 'Availability',
        url: `/settings/availability`,
        component: () => <Box>Availability settings</Box>,
        // MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Waitlist',
        url: `/settings/waitlist`,
        component: () => <Box>Waitlist settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Online booking',
        url: `/settings/online-booking`,
        component: () => <Box>Online booking settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
    ],
  },
  {
    title: 'Billing',
    items: [
      {
        title: 'Strategy & policies',
        url: `/settings/billing-strategy`,
        component: BillingStrategySettings,
      },
      {
        title: 'Discounts',
        url: `/settings/discounts`,
        component: () => <Box>Discounts settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Payments',
        url: `/settings/payments`,
        component: () => <Box>Payments settings</Box>,
        // MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Cancellation policies',
        url: `/settings/cancellations`,
        component: CancellationSettings,
      },
    ],
  },
  {
    title: 'Business',
    items: [
      {
        title: 'Overview',
        url: `/settings`,
        exactSelection: true,
        component: () => <Box>Overview settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'General',
        url: `/settings/general`,
        component: GeneralSettings,
      },
      {
        title: 'Services',
        url: `/settings/services`,
        component: ServiceSettings,
      },
      {
        title: 'Communications',
        url: `/settings/communications`,
        component: () => <Box>Communications settings</Box>,
        // Not sure if we need this for MVP or post MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Staff',
        url: `/settings/staff`,
        component: () => <Box>Staff settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Locations',
        url: `/settings/locations`,
        component: () => <Box>Locations settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Plans',
        url: `/settings/plans`,
        component: () => <Box>Plans settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Import/export',
        url: `/settings/import-export`,
        component: () => <Box>Import/Export settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Integrations',
        url: `/settings/integrations`,
        component: () => <Box>Integrations settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
    ],
  },
];

export const useSettingsAppPageLists = (): AppPageList[] => {
  const [featureFlags] = useFeatureFlags();

  return useMemo(
    () =>
      settingsAppPageLists.flatMap((group) => {
        const items = group.items.filter(
          ({ featureFlag }) =>
            featureFlag === undefined || featureFlags[featureFlag]
        );

        return items.length ? { ...group, items } : [];
      }),
    [featureFlags]
  );
};
