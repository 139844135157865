import Typography, { TypographyProps } from '@mui/joy/Typography';
import { forwardRef } from 'react';

const tagMap = {
  teacher: 'Teacher',
  primary: 'Primary contact',
  student: 'Student',
  potentialStudent: 'Potential Student',
};

interface ContactTagProps extends TypographyProps {
  type: keyof typeof tagMap;
}

export const ContactTag = ({ type, sx, ...props }: ContactTagProps) => (
  <Tag sx={sx} {...props}>
    {tagMap[type]}
  </Tag>
);

export type TagProps = TypographyProps;

export const Tag = forwardRef<HTMLSpanElement, TagProps>(
  ({ fontSize, sx, ...props }, ref) => (
    <Typography
      ref={ref}
      className="tag-root"
      fontSize={fontSize || 12}
      sx={[
        (theme) => ({
          textAlign: 'center',
          borderRadius: '2px',
          padding: '0 8px',
          width: 'fit-content',
          color: theme.palette.text.primary,
          background: theme.palette.background.level1,
          display: 'flex',
          alignItems: 'center',
          gap: '3px',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
);
