import { Box, Typography, boxClasses, typographyClasses } from '@mui/joy';
import { useMemo } from 'react';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const ContactDrawerInfo = () => {
  const { currentContact } = useContactsProvider();

  const primaryPhone = currentContact?.phones?.find((phone) => phone.isPrimary);
  const primaryEmail = currentContact?.emails?.find((email) => email.isPrimary);
  const primaryAddress = currentContact?.addresses?.[0];
  const age = useMemo(() => {
    if (!currentContact?.birthday) {
      return null;
    }

    const diff = Date.now() - currentContact.birthday.toDate().getTime();
    const ageDate = new Date(diff);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [currentContact]);

  return (
    <>
      {primaryPhone ? (
        <Box>
          <Typography variant="label">Phone</Typography>
          <Typography
            level="title-md"
            sx={
              primaryPhone.number
                ? (theme) => ({
                    color: theme.palette.primary[400],
                    cursor: 'pointer',
                  })
                : undefined
            }
          >
            {primaryPhone.number}
          </Typography>
        </Box>
      ) : null}

      {primaryEmail ? (
        <Box>
          <Typography variant="label">Email</Typography>
          <Typography
            level="title-md"
            sx={
              primaryEmail.address
                ? (theme) => ({
                    color: theme.palette.primary[400],
                    cursor: 'pointer',
                  })
                : undefined
            }
          >
            {primaryEmail.address}
          </Typography>
        </Box>
      ) : null}

      {primaryAddress ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',

            [`& > .${boxClasses.root}`]: {
              flex: primaryAddress ? '1 1 50%' : 1,
            },
          }}
        >
          <Box
            sx={{
              [`& .${typographyClasses.root}.MuiTypography-variantValue`]: {
                lineHeight: 1.3,
                fontWeight: 400,
              },
            }}
          >
            <Typography variant="label">Address</Typography>
            <Typography level="title-md">
              {[primaryAddress.street1, primaryAddress.street2]
                .filter(Boolean)
                .join(', ')}
            </Typography>
            <Typography level="title-md">
              {primaryAddress.city}, {primaryAddress.state} {primaryAddress.zip}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {currentContact?.birthday ? (
        <Box>
          <Typography variant="label">Birthday</Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <Typography level="title-md">
              {currentContact.birthday.toDate().toLocaleDateString()}
            </Typography>

            {age !== null && age <= 18 ? (
              <Typography color="gray">
                ({age} year{age !== 1 ? 's' : ''} old)
              </Typography>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
};
