import {
  ChatBubbleOutline,
  HelpOutlineOutlined,
  Menu,
  NotificationsNoneOutlined,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  buttonClasses,
  iconButtonClasses,
} from '@mui/joy';
import { useMediaQuery } from '@mui/material';

import { ProfileButton } from './ProfileButton';

const topBarHeight = 56;

interface ContentContainerShellProps {
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
  backArea?: React.ReactNode;
}

export const ContentContainerShell = ({
  children,
  sidebar,
  showSidebar,
  setShowSidebar,
  backArea,
}: ContentContainerShellProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!isDesktop) {
    return <>{children}</>;
  }

  return (
    <Box>
      <Box
        sx={{
          height: topBarHeight,
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 0px 4px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          [`& .${iconButtonClasses.root}, & .${buttonClasses.root}`]: {
            color: 'black',
            transition: 'opacity 0.33s',

            '&:hover': {
              opacity: 0.5,
            },
          },
        }}
      >
        <Box>
          {backArea ?? (
            <IconButton
              sx={{ padding: '12px 30px' }}
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <Menu />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '400px',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <Button
            variant="plain"
            color="neutral"
            sx={{
              fontFamily: '"Times New Roman", Times, serif',
              fontSize: '38px',
            }}
          >
            H
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            paddingRight: '22px',
          }}
        >
          <IconButton>
            <Search />
          </IconButton>
          <IconButton>
            <ChatBubbleOutline />
          </IconButton>
          <IconButton>
            <NotificationsNoneOutlined />
          </IconButton>
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
          <ProfileButton />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          minHeight: `calc(100vh - ${topBarHeight}px)`,
          width: '100%',
        }}
      >
        {sidebar}
        <Box sx={{ position: 'relative', flex: 1, minWidth: 0 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
