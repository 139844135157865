import {
  IonActionSheet,
  IonIcon,
  IonItem,
  IonList,
  useIonRouter,
} from '@ionic/react';
import {
  AccountCircleOutlined,
  ArrowForwardIos,
  AttachMoneyOutlined,
  CompareArrowsOutlined,
  CreateOutlined,
  EmailOutlined,
  InfoOutlined,
  MoreHorizOutlined,
  PersonAddAltOutlined,
  PhoneOutlined,
  SwitchAccountOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dropdown,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { ParsedContact, RawContact } from '@shared/models/contacts';
import { RawLesson } from '@shared/models/lessons';
import { RawUser } from '@shared/models/users';
import {
  Timestamp,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { calendar, chatbubbleOutline, chevronForward } from 'ionicons/icons';
import { useCallback, useMemo, useState } from 'react';

import Avatar from '@components/Avatar';
import { AvatarBadge } from '@components/AvatarBadge';
import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { IonicLink2 } from '@components/IonicLink2';
import { ListHeader } from '@components/ListHeader';
import { ListHelperText } from '@components/ListHelperText';
import { Add } from '@components/icons/Add';
import { useContactAvatar } from '@components/useContactAvatar';
import { InvoiceHeader } from '@features/Admin/Invoices/InvoiceDetail/InvoiceHeader';
import { getInvoiceDateRange } from '@features/Admin/Invoices/InvoiceDetail/ViewInvoice';
import { initAssignment } from '@features/Lessons/LessonDetail/LessonProvider/flattenAssignmentItems';
import {
  useOrgainzation,
  useOrganizationId,
  useOrganizationPermissions,
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import { useUnfurlEvents } from '@features/Schedule/useUnfurlEvents';
import { accountsCollection } from '@models/accounts/model';
import { assignmentItemsCollection } from '@models/assignmentItems/model';
import { contactsCollection } from '@models/contacts/model';
import { notEmpty, useEventsQuery } from '@models/events/useEventsQuery';
import { useInvoicesQuery } from '@models/invoices/useInvoicesQuery';
import { lessonsCollection } from '@models/lessons/model';
import { useLessonsQuery } from '@models/lessons/useLessonsQuery';
import { themeColors } from '@theme/theme';
import { serverTimestamp } from '@utils/serverTimestamp';

import { ContactTags } from './ContactTags';
import { HorizontalList } from './HorizontalList';
import { LinkedContact } from './LinkedContact';
import { MoveContactsModal } from './MoveContactsModal';
import { NotePreview } from './NotePreview';
import { RelatedEvent } from './RelatedEvent';
import { RelatedEventContainer } from './RelatedEventContainer';
import StartLessonModal from './StartLessonModal';
import { TransferPrimaryModal } from './TransferPrimaryModal';
import { UserAccessModal } from './UserAccessModal';

export const getContactInitials = (
  contact: Pick<ParsedContact, 'firstName' | 'lastName'>
) => [contact.firstName[0], contact.lastName[0]].filter(Boolean).join('');

export const getContactName = (
  contact: ParsedContact | undefined,
  user: RawUser | undefined
) => {
  if (!contact) {
    return '';
  }

  const base = [contact.firstName, contact.lastName].filter(Boolean).join(' ');

  return `${base}${
    contact.userId && user?.userId === contact.userId ? ' (me)' : ''
  }`;
};

export const sidePadding = 16;

const getSimpleDate = (input: Date = new Date()) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[input.getMonth()];
  const day = input.getDate();

  return `${month} ${day}`;
};

interface ViewContactProps {
  contact: ParsedContact | undefined;
  onAddContact?: (preset?: Partial<RawContact>) => void;
  onAddEvent?: () => void;
  setIsLinking?: (isLinking: boolean) => void;
  linkedContacts: ParsedContact[] | undefined;
  page: React.MutableRefObject<HTMLElement | null>;
  onEdit?: () => void;
  onContactSelection?: (contactId: string) => void;
}

export const ViewContact = ({
  contact,
  onAddContact,
  onAddEvent,
  linkedContacts,
  onEdit,
  page,
  onContactSelection,
}: ViewContactProps) => {
  // TODO: handle helping users set up an account that has no students
  // const accountHasStudent =
  //   contact?.tags.student ||
  //   linkedContacts?.some((linkedContact) => linkedContact.tags.student);

  const organizationSlug = useOrganizationSlug();
  const organizationId = useOrganizationId();
  const router = useIonRouter();
  const user = useUser();
  const permissions = useOrganizationPermissions();

  const isTeacher = Boolean(permissions?.isTeacher);
  const isDesktop = useMediaQuery('(min-width:747px)');

  const [idToUnlink, setIdToUnlink] = useState<string | undefined>();
  const [startLessonModalOpen, setStartLessonModalOpen] = useState(false);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const [moveContactModalOpen, setMoveContactModalOpen] = useState(false);
  const [transferPrimaryModalOpen, setTransferPrimaryModalOpen] =
    useState(false);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [newPrimaryContact, setNewPrimaryContact] = useState<
    ParsedContact | undefined
  >(undefined);
  const messagingEnabled = useFeatureFlag('messaging');
  const organization = useOrgainzation();
  const schedulingV1_3Enabled = useFeatureFlag('schedulingV1_3Enabled');
  const nonTeachersCanInviteEnabled = useFeatureFlag(
    'allowNonTeachersToInvite'
  );
  const lessonNotesV1Enabled = useFeatureFlag('lessonNotesV1_1Enabled');

  const currentDate = new Date();

  /**
   * Contact queries
   */
  const [rawRelatedEvents] = useEventsQuery({
    currentDate,
    constraints: contact
      ? [where('accountIds', 'array-contains', contact.accountId)]
      : [],
    skip: !contact,
  });
  const [invoices] = useInvoicesQuery({
    limit: 1,
    skip: !contact,
    constraints: contact ? [where('accountId', '==', contact.accountId)] : [],
  });
  const [lessonNotes] = useLessonsQuery({
    limit: 6,
    skip: !contact,
    constraints: contact ? [where('contactId', '==', contact.id)] : [],
  });

  const relatedEvents = useUnfurlEvents({
    events: rawRelatedEvents,
    startDate: currentDate,
    limit: 12,
    startOf: 'hour',
  });

  const recentInvoice = invoices?.[0];

  const startLesson = useCallback(async () => {
    if (!user) {
      throw new Error('User not found');
    }

    if (!contact) {
      throw new Error('Contact not found');
    }

    if (!organizationId) {
      throw new Error('Organization not found');
    }

    const nextLesson: RawLesson = {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: user.userId,
      updatedBy: user.userId,
      title: lessonNotesV1Enabled
        ? getSimpleDate()
        : `${contact.firstName}'${
            contact.firstName.slice(-1) === 's' ? '' : 's'
          } Piano Lesson`,
      description: '',
      status: 'draft',
      lessonDate: Timestamp.now(),
      accountId: contact.accountId,
      contactId: contact.id,
      organizationId,
    };

    const partialContact: Partial<RawContact> = {
      updatedAt: serverTimestamp(),
      updatedBy: user.userId,
      tags: { ...contact.tags, student: true },
    };

    const [nextLessonRecord] = await Promise.all([
      addDoc(lessonsCollection, nextLesson),
      !contact.tags.student
        ? updateDoc(doc(contactsCollection, contact.id), partialContact)
        : undefined,
    ]);

    const nextAssignment = initAssignment({
      createdBy: user.userId,
      updatedBy: user.userId,
      isPlaceholder: true,
      index: 0,
      organizationId,
      lessonId: nextLessonRecord.id,
      accountId: nextLesson.accountId,
      parentId: null,
      sectionId: null,
    });

    await setDoc(
      doc(assignmentItemsCollection, nextAssignment.id),
      nextAssignment
    );

    router.push(
      `/${organizationSlug}/lesson-notes/${nextLessonRecord.id}`,
      isDesktop ? 'none' : 'forward'
    );
  }, [
    contact,
    user,
    organizationId,
    organizationSlug,
    router,
    isDesktop,
    lessonNotesV1Enabled,
  ]);

  const primaryPhoneNumber = contact?.phones?.find(
    (phone) => phone.isPrimary
  )?.number;
  const primaryEmail = contact?.emails?.find(
    (email) => email.isPrimary
  )?.address;

  const otherLinkedContacts = contact
    ? linkedContacts?.filter(({ id }) => id !== contact.id)
    : undefined;

  const [avatarSrc] = useContactAvatar(contact);

  const quickActions = useMemo(
    () =>
      [
        primaryPhoneNumber
          ? {
              icon: (
                <IonIcon
                  style={{ fontSize: '18px' }}
                  size="lg"
                  ios={chatbubbleOutline}
                />
              ),
              text: 'Message',
              href: `sms:${primaryPhoneNumber}`,
            }
          : undefined,
        primaryPhoneNumber
          ? {
              icon: <PhoneOutlined sx={{ color: 'currentcolor' }} />,
              text: 'Call',
              href: `tel:${primaryPhoneNumber}`,
            }
          : undefined,

        primaryEmail
          ? {
              icon: <EmailOutlined sx={{ color: 'currentcolor' }} />,
              text: 'Mail',
              href: `mailto:${primaryEmail}`,
            }
          : undefined,
        schedulingV1_3Enabled
          ? {
              icon: <AttachMoneyOutlined sx={{ color: 'currentcolor' }} />,
              text: 'Pay',
            }
          : undefined,
        contact?.tags.primaryContact && contact.userId !== user?.userId
          ? {
              icon: contact.userInviteStatus ? (
                <AccountCircleOutlined sx={{ color: 'currentcolor' }} />
              ) : (
                <PersonAddAltOutlined sx={{ color: 'currentcolor' }} />
              ),
              text: {
                accepted: 'Account',
                pending: 'Account',
                undefined: 'Invite',
              }[`${contact.userInviteStatus}`],
              longText: {
                accepted: 'Manage User',
                pending: 'Manage Invite',
                undefined: 'Invite User',
              }[`${contact.userInviteStatus}`],
              onClick: () => setInviteUserModalOpen(true),
              testId: 'invite-user-button',
            }
          : undefined,
        contact?.tags.primaryContact && (otherLinkedContacts || [])?.length > 0
          ? {
              icon: <SwitchAccountOutlined sx={{ color: 'currentcolor' }} />,
              text: 'Transfer',
              longText: 'Transfer primary contact',
              onClick: () => setTransferPrimaryModalOpen(true),
              testId: 'transfer-primary-contact-button',
            }
          : undefined,
        contact?.tags.primaryContact
          ? {
              icon: (
                <Box
                  sx={{
                    height: '18px',
                    width: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '15px',
                      height: '15px',
                      overflow: 'hidden',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1.5px solid black',
                    }}
                  >
                    <CompareArrowsOutlined
                      sx={{
                        color: 'currentcolor',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </Box>
                </Box>
              ),
              text: 'Move',
              longText: 'Move Connected Contacts',
              onClick: () => setMoveContactModalOpen(true),
              testId: 'move-connected-contacts',
            }
          : undefined,
      ].filter(notEmpty),
    [
      contact,
      primaryPhoneNumber,
      primaryEmail,
      user,
      otherLinkedContacts,
      schedulingV1_3Enabled,
    ]
  );

  const aboveTheFoldQuickActionsBase = schedulingV1_3Enabled ? 4 : 3;
  const aboveTheFoldQuickActions =
    quickActions.length === aboveTheFoldQuickActionsBase + 1
      ? aboveTheFoldQuickActionsBase + 1
      : aboveTheFoldQuickActionsBase;
  const showMoreButton = quickActions.length > aboveTheFoldQuickActions + 1;

  // const showQuickActions =
  //   (contact?.tags.primaryContact || quickActions.length) &&
  //   isTeacher &&
  //   user?.userId !== contact?.userId;

  const quickActionsList = (
    <>
      {quickActions
        .slice(0, aboveTheFoldQuickActions)
        .map(({ icon, text, href, onClick, testId }, index) => (
          <Button
            variant="roundedButton"
            color="neutral"
            key={index}
            data-testid={testId}
            {...(onClick
              ? { onClick }
              : {
                  component: 'a',
                  target: '_blank',
                  href,
                })}
          >
            {icon}
            {text}
          </Button>
        ))}

      {isDesktop ? (
        <Button variant="roundedButton" color="neutral" onClick={onEdit}>
          <CreateOutlined sx={{ color: 'currentcolor' }} />
          Edit
        </Button>
      ) : null}
      {(isTeacher || nonTeachersCanInviteEnabled) && showMoreButton ? (
        <>
          {isDesktop ? (
            <Dropdown>
              <MenuButton
                slots={{ root: Button }}
                slotProps={{
                  root: { variant: 'roundedButton', color: 'neutral' },
                }}
              >
                <MoreHorizOutlined sx={{ color: 'currentcolor' }} />
                More
              </MenuButton>
              <Menu placement="bottom-start">
                {quickActions
                  .slice(aboveTheFoldQuickActions)
                  .map(({ testId, onClick, icon, longText, href }, index) => (
                    <MenuItem
                      key={index}
                      data-testid={testId}
                      {...(onClick
                        ? { onClick }
                        : {
                            component: 'a',
                            target: '_blank',
                            href,
                          })}
                    >
                      <ListItemDecorator>{icon}</ListItemDecorator> {longText}
                    </MenuItem>
                  ))}
              </Menu>
            </Dropdown>
          ) : (
            <Button
              variant="roundedButton"
              color="neutral"
              onClick={() => setMoreMenuOpen(true)}
            >
              <MoreHorizOutlined sx={{ color: 'currentcolor' }} />
              More
            </Button>
          )}
        </>
      ) : null}
    </>
  );

  if (!contact || !organizationId) {
    return null;
  }

  return (
    <>
      {/* Desktop quick actions */}
      {isDesktop ? (
        <Box
          sx={{
            gap: '10px',
            padding: `10px 14px`,
            display: 'flex',
            width: 'fit-content',
            marginLeft: 'auto',
          }}
        >
          {quickActionsList}
        </Box>
      ) : null}

      {/* Profile area */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          padding: '0px 16px',
          marginTop: '15px',
          '@media (min-width: 747px)': {
            marginTop: 0,
            justifyContent: 'space-between',
            marginBottom: '55px',
            alignItems: 'center',
            gap: '10px',
          },
        }}
      >
        <Box
          sx={{
            '@media (min-width: 747px)': {
              display: 'flex',
              flex: 1,
            },
          }}
        >
          <Box
            sx={{
              '@media (min-width: 747px)': {
                marginLeft: 'auto',
              },
            }}
          >
            <AvatarBadge isPrimary={contact.tags.primaryContact}>
              <Avatar
                sx={() => ({
                  width: 80,
                  height: 80,
                  fontSize: 20,
                  fontWeight: 400,
                  marginRight: 1,
                  background: themeColors.lightGreen,
                  '@media (min-width: 747px)': {
                    width: '120px',
                    height: '120px',
                  },
                })}
                src={avatarSrc ?? undefined}
              >
                {getContactInitials(contact)}
              </Avatar>
            </AvatarBadge>
          </Box>
        </Box>
        <Box
          sx={{
            '@media (min-width: 747px)': {
              flex: 1,
            },
          }}
        >
          <Typography fontSize={20} data-testid="contact-name">
            {getContactName(contact, user)}
          </Typography>
          <ContactTags contact={contact} />
        </Box>
      </Box>

      {/* Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          '@media (min-width: 747px)': {
            gap: '35px',
          },
        }}
      >
        {/* Mobile Quick Actions */}
        {!isDesktop ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                gap: '10px',
                padding: `8px ${sidePadding - 3}px`,
                display: 'flex',
                width: 'fit-content',
                margin: '0 auto',
              }}
            >
              {quickActionsList}
            </Box>
          </Box>
        ) : null}

        {/* Lesson Notes */}
        {contact.tags.student ? (
          <Box>
            <ListHeader v2 title="Lesson Notes" />
            <Box
              sx={{
                padding: '12px 16px 16px 16px',
                display: 'flex',
                gap: '20px',
                overflowX: 'auto',
                width: '100%',
                '@media (min-width: 747px)': {
                  padding: '20px 36px',
                },

                '& > div': {
                  flexShrink: 0,

                  '@media (min-width: 747px)': {
                    '&:first-of-type': {
                      marginLeft: '0px',
                    },
                    '&:last-of-type': {
                      marginRight: '10px',
                    },
                  },
                },
              }}
            >
              {permissions?.lessons.canCreate && contact.instruments?.length ? (
                <NotePreview
                  title="New Note"
                  special
                  onClick={async () => {
                    if (!contact.instruments?.length) {
                      throw new Error('Instruments not found');
                    }

                    if (contact.instruments.length > 1) {
                      setStartLessonModalOpen(true);
                    } else {
                      startLesson();
                    }
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      '& svg': {
                        color: theme.palette.text.primary,
                        fontSize: '60px',
                      },
                    })}
                  >
                    <Add />
                  </Box>
                </NotePreview>
              ) : null}

              {lessonNotes
                ?.reverse()
                .slice(0, 5)
                .map((note, noteIndex) => (
                  <IonicLink2
                    key={note.id}
                    unstyled
                    routerLinkProps={{
                      routerLink: `/${organizationSlug}/lesson-notes/${note.id}`,
                      routerDirection: isDesktop ? 'none' : 'forward',
                    }}
                    dataTestId={`lesson-note-${noteIndex}`}
                  >
                    <NotePreview
                      title={note.lessonDate.toDate().toLocaleDateString()}
                      // TODO: add instrument to note
                      // subTitle={note.instrument}
                      subTitle="Piano"
                    >
                      <img
                        src="assets/example-lesson-note-preview.png"
                        alt="loading…"
                        style={{
                          width: '100%',
                          background: 'lightgray',
                          borderRadius: '4px',
                        }}
                      />
                    </NotePreview>
                  </IonicLink2>
                ))}

              {(lessonNotes?.length ?? 0) > 5 ? (
                <IonicLink2
                  unstyled
                  routerLinkProps={{
                    routerLink: `/${organizationSlug}/lesson-notes?c=${contact.id}`,
                    routerDirection: isDesktop ? 'none' : 'forward',
                  }}
                >
                  <NotePreview title="All Lesson Notes" special>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <ArrowForwardIos
                        sx={{ fontSize: '40px', color: 'black' }}
                      />
                    </Box>
                  </NotePreview>
                </IonicLink2>
              ) : null}
            </Box>
          </Box>
        ) : null}

        {/* Scheduling */}
        {organization?.plan === 'pro' ? (
          <>
            <ListHeader v2 firstHeader title="Schedule" />
            {rawRelatedEvents === undefined ? null : (
              <IonList>
                <HorizontalList>
                  {relatedEvents.slice(0, 11).map((event, index) => (
                    <RelatedEvent key={index} event={event} />
                  ))}

                  {relatedEvents.length > 11 ? (
                    <RelatedEventContainer
                      left={
                        <IonIcon
                          ios={calendar}
                          md={calendar}
                          style={{ fontSize: 25 }}
                        />
                      }
                      primary={
                        <>
                          View Calendar
                          <IonIcon
                            ios={chevronForward}
                            md={chevronForward}
                            style={{
                              fontSize: 12,
                              marginBottom: -2,
                            }}
                          />
                        </>
                      }
                    />
                  ) : null}
                </HorizontalList>
                <Button
                  variant="plain"
                  color="neutral"
                  startDecorator={<Add />}
                  onClick={onAddEvent}
                  sx={{ marginLeft: '22px' }}
                >
                  Add Lesson
                </Button>
              </IonList>
            )}

            <ListHeader v2 title="Invoices & Payments" />
            <Box
              sx={{
                padding: isDesktop ? '0 22px' : undefined,
              }}
            >
              <IonList>
                {recentInvoice ? (
                  <IonicLink2
                    unstyled
                    routerLinkProps={{
                      routerLink: `/${organizationSlug}/admin/invoices/${recentInvoice.id}`,
                      routerDirection: isDesktop ? 'none' : 'forward',
                      style: { position: 'relative', zIndex: 2 },
                    }}
                    spanProps={{ style: { position: 'relative', zIndex: 2 } }}
                  >
                    <Box
                      sx={{
                        borderRadius: '10px',
                        boxShadow: '0 5px 15px #e7e7e7',
                        padding: '15px 0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '10px 10px 0 10px',
                        '@media (min-width: 747px)': {
                          padding: '35px 0 20px 0',
                        },
                      }}
                    >
                      <InvoiceHeader
                        amount={recentInvoice.amount}
                        invoiceStatus={recentInvoice.status}
                        size="small"
                        sx={{
                          padding: '10px 0',
                          marginRight: '6px',
                        }}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'flex-end',
                          padding: '0 10px',
                        }}
                      >
                        <Box>
                          <Typography fontSize={12} sx={{ color: 'gray' }}>
                            Invoice Period
                          </Typography>
                          <Typography fontSize={12} sx={{ color: 'gray' }}>
                            {getInvoiceDateRange(recentInvoice)}
                          </Typography>
                        </Box>
                        <Button
                          variant="plain"
                          sx={{
                            color: themeColors.darkGreen,
                            padding: 0,
                            minHeight: 30,
                            marginBottom: '-6px',
                          }}
                        >
                          View Invoice{' '}
                          <IonIcon
                            ios={chevronForward}
                            md={chevronForward}
                            style={{
                              fontSize: 12,
                              marginBottom: -2,
                            }}
                          />
                        </Button>
                      </Box>
                    </Box>
                  </IonicLink2>
                ) : null}
                <Button
                  variant="plain"
                  color="neutral"
                  sx={{
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}
                  onClick={() => {
                    router.push(
                      `/${organizationSlug}/admin/invoices?a=${contact.accountId}`
                    );
                  }}
                >
                  All Invoices
                </Button>
              </IonList>
            </Box>
          </>
        ) : null}

        {/* Connected contacts */}
        <Box>
          {isTeacher ? <ListHeader v2 title="Connected Contacts" /> : null}

          {isTeacher === false && otherLinkedContacts?.length ? (
            <ListHeader v2 title="Contacts" />
          ) : null}

          {isTeacher || otherLinkedContacts?.length ? (
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                gap: '15px',
                padding: '12px 16px 10px 16px',
                borderRadius: '5px',
                maxWidth: '100%',

                '@media (min-width: 747px)': {
                  padding: '20px 36px',
                  gap: '20px',
                },
              }}
            >
              <Button
                variant="verticalOutline"
                startDecorator={<Add />}
                onClick={() => onAddContact?.({ accountId: contact.accountId })}
              >
                Add
              </Button>

              {otherLinkedContacts?.map((linkedContact) => (
                <LinkedContact
                  key={linkedContact.id}
                  linkedContact={linkedContact}
                  onContactSelection={onContactSelection}
                />
              ))}
            </Box>
          ) : null}

          {!isTeacher || (otherLinkedContacts?.length || 0) > 0 ? null : (
            <ListHelperText
              sx={{
                alignItems: 'center',
                ...(isDesktop && { marginLeft: '16px' }),
              }}
            >
              Looks like this contact is solo. Want to add some accompaniment?{' '}
              <Tooltip
                title="Connected contacts help keep related contacts in one place. Each group has a primary contact who receives account-level emails and notifications."
                variant="plain"
              >
                <InfoOutlined sx={{ marginBottom: '-3px', fontSize: '14px' }} />
              </Tooltip>
            </ListHelperText>
          )}
        </Box>

        {messagingEnabled ? (
          <IonList>
            <IonItem routerLink="#invoice">Messages</IonItem>
            <IonItem routerLink="#invoice">Call log</IonItem>
          </IonList>
        ) : null}
      </Box>

      {/* Modals */}
      <>
        <IonActionSheet
          isOpen={Boolean(idToUnlink)}
          header="Are you sure you want to unlink this contact?"
          buttons={[
            {
              text: 'Unlink',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: async () => {
                if (!user) {
                  throw new Error('User not found');
                }

                const accountDocument = await addDoc(accountsCollection, {
                  organizationId,
                  createdAt: serverTimestamp(),
                  updatedAt: serverTimestamp(),
                  createdBy: user.userId,
                  updatedBy: user.userId,
                });

                updateDoc(doc(contactsCollection, idToUnlink), {
                  updatedAt: serverTimestamp(),
                  updatedBy: user.userId,
                  accountId: accountDocument.id,
                });
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIdToUnlink(undefined)}
        />
        <StartLessonModal
          adding={startLessonModalOpen}
          page={page}
          onDone={(instruments) => {
            setStartLessonModalOpen(false);

            if (instruments?.length) {
              setTimeout(() => startLesson(), 300);
            }
          }}
        />
        <MoveContactsModal
          page={page}
          isOpen={moveContactModalOpen}
          onDone={() => setMoveContactModalOpen(false)}
          accountId={contact.accountId}
        />
        <TransferPrimaryModal
          page={page}
          isOpen={transferPrimaryModalOpen}
          onDone={() => setTransferPrimaryModalOpen(false)}
          contactId={contact.id}
          linkedContacts={linkedContacts}
        />
        <UserAccessModal
          page={page}
          isOpen={inviteUserModalOpen}
          onDone={() => setInviteUserModalOpen(false)}
          contact={contact}
        />

        <IonActionSheet
          isOpen={moreMenuOpen}
          buttons={[
            ...quickActions
              .slice(aboveTheFoldQuickActions)
              .map(({ longText, href, onClick }) => ({
                text: longText,
                handler:
                  onClick ??
                  (() => {
                    if (href) {
                      router.push(href);
                    }
                  }),
              })),
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setMoreMenuOpen(false)}
        />
        <IonActionSheet
          isOpen={Boolean(newPrimaryContact)}
          header={`Are you sure you want to make ${newPrimaryContact?.firstName} the primary contact? ${contact.firstName} will no longer receive account-level emails or notifications.`}
          buttons={[
            {
              text: `Make ${newPrimaryContact?.firstName} the primary contact`,
              handler: async () => {
                if (!user) {
                  throw new Error('User not found');
                }

                if (!newPrimaryContact) {
                  return;
                }

                await updateDoc(doc(contactsCollection, contact.id), {
                  updatedAt: serverTimestamp(),
                  updatedBy: user.userId,
                  tags: { ...contact.tags, primaryContact: false },
                });
                await updateDoc(doc(contactsCollection, newPrimaryContact.id), {
                  updatedAt: serverTimestamp(),
                  updatedBy: user.userId,
                  tags: { ...newPrimaryContact.tags, primaryContact: true },
                });
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setNewPrimaryContact(undefined)}
        />
      </>
    </>
  );
};
