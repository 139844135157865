import { ParsedContact } from '@shared/models/contacts';
import { useMemo } from 'react';

export interface EnhancedContact extends ParsedContact {
  relatedContacts: ParsedContact[];
}

export const useEnhancedContacts = (contacts: ParsedContact[] | undefined) => {
  return useMemo(() => {
    const contactsByAccountId = (contacts || []).reduce<
      Record<string, ParsedContact[]>
    >(
      (previous, contact) => ({
        ...previous,
        [contact.accountId]: [...(previous[contact.accountId] || []), contact],
      }),
      {}
    );

    return contacts?.map(
      (contact): EnhancedContact => ({
        ...contact,
        relatedContacts: (contactsByAccountId[contact.accountId] || []).filter(
          (relatedContact) => relatedContact.id !== contact.id
        ),
      })
    );
  }, [contacts]);
};
