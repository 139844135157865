export const countries = [
  { key: 'usa', name: 'United States' },
  { key: 'afg', name: 'Afghanistan' },
  { key: 'alb', name: 'Albania' },
  { key: 'dza', name: 'Algeria' },
  { key: 'and', name: 'Andorra' },
  { key: 'ago', name: 'Angola' },
  { key: 'atg', name: 'Antigua and Barbuda' },
  { key: 'arg', name: 'Argentina' },
  { key: 'arm', name: 'Armenia' },
  { key: 'aus', name: 'Australia' },
  { key: 'aut', name: 'Austria' },
  { key: 'aze', name: 'Azerbaijan' },
  { key: 'bhs', name: 'Bahamas' },
  { key: 'bhr', name: 'Bahrain' },
  { key: 'bgd', name: 'Bangladesh' },
  { key: 'brb', name: 'Barbados' },
  { key: 'blr', name: 'Belarus' },
  { key: 'bel', name: 'Belgium' },
  { key: 'blz', name: 'Belize' },
  { key: 'ben', name: 'Benin' },
  { key: 'btn', name: 'Bhutan' },
  { key: 'bol', name: 'Bolivia, Plurinational State of' },
  { key: 'bih', name: 'Bosnia and Herzegovina' },
  { key: 'bwa', name: 'Botswana' },
  { key: 'bra', name: 'Brazil' },
  { key: 'brn', name: 'Brunei Darussalam' },
  { key: 'bgr', name: 'Bulgaria' },
  { key: 'bfa', name: 'Burkina Faso' },
  { key: 'bdi', name: 'Burundi' },
  { key: 'cpv', name: 'Cabo Verde' },
  { key: 'khm', name: 'Cambodia' },
  { key: 'cmr', name: 'Cameroon' },
  { key: 'can', name: 'Canada' },
  { key: 'caf', name: 'Central African Republic' },
  { key: 'tcd', name: 'Chad' },
  { key: 'chl', name: 'Chile' },
  { key: 'chn', name: 'China' },
  { key: 'col', name: 'Colombia' },
  { key: 'com', name: 'Comoros' },
  { key: 'cog', name: 'Congo' },
  { key: 'cod', name: 'Congo, Democratic Republic of the' },
  { key: 'cri', name: 'Costa Rica' },
  { key: 'civ', name: "Côte d'Ivoire" },
  { key: 'hrv', name: 'Croatia' },
  { key: 'cub', name: 'Cuba' },
  { key: 'cyp', name: 'Cyprus' },
  { key: 'cze', name: 'Czechia' },
  { key: 'dnk', name: 'Denmark' },
  { key: 'dji', name: 'Djibouti' },
  { key: 'dma', name: 'Dominica' },
  { key: 'dom', name: 'Dominican Republic' },
  { key: 'ecu', name: 'Ecuador' },
  { key: 'egy', name: 'Egypt' },
  { key: 'slv', name: 'El Salvador' },
  { key: 'gnq', name: 'Equatorial Guinea' },
  { key: 'eri', name: 'Eritrea' },
  { key: 'est', name: 'Estonia' },
  { key: 'swz', name: 'Eswatini' },
  { key: 'eth', name: 'Ethiopia' },
  { key: 'fji', name: 'Fiji' },
  { key: 'fin', name: 'Finland' },
  { key: 'fra', name: 'France' },
  { key: 'gab', name: 'Gabon' },
  { key: 'gmb', name: 'Gambia' },
  { key: 'geo', name: 'Georgia' },
  { key: 'deu', name: 'Germany' },
  { key: 'gha', name: 'Ghana' },
  { key: 'grc', name: 'Greece' },
  { key: 'grd', name: 'Grenada' },
  { key: 'gtm', name: 'Guatemala' },
  { key: 'gin', name: 'Guinea' },
  { key: 'gnb', name: 'Guinea-Bissau' },
  { key: 'guy', name: 'Guyana' },
  { key: 'hti', name: 'Haiti' },
  { key: 'hnd', name: 'Honduras' },
  { key: 'hun', name: 'Hungary' },
  { key: 'isl', name: 'Iceland' },
  { key: 'ind', name: 'India' },
  { key: 'idn', name: 'Indonesia' },
  { key: 'irn', name: 'Iran, Islamic Republic of' },
  { key: 'irq', name: 'Iraq' },
  { key: 'irl', name: 'Ireland' },
  { key: 'isr', name: 'Israel' },
  { key: 'ita', name: 'Italy' },
  { key: 'jam', name: 'Jamaica' },
  { key: 'jpn', name: 'Japan' },
  { key: 'jor', name: 'Jordan' },
  { key: 'kaz', name: 'Kazakhstan' },
  { key: 'ken', name: 'Kenya' },
  { key: 'kir', name: 'Kiribati' },
  { key: 'prk', name: "Korea, Democratic People's Republic of" },
  { key: 'kor', name: 'Korea, Republic of' },
  { key: 'kwt', name: 'Kuwait' },
  { key: 'kgz', name: 'Kyrgyzstan' },
  { key: 'lao', name: "Lao People's Democratic Republic" },
  { key: 'lva', name: 'Latvia' },
  { key: 'lbn', name: 'Lebanon' },
  { key: 'lso', name: 'Lesotho' },
  { key: 'lbr', name: 'Liberia' },
  { key: 'lby', name: 'Libya' },
  { key: 'lie', name: 'Liechtenstein' },
  { key: 'ltu', name: 'Lithuania' },
  { key: 'lux', name: 'Luxembourg' },
  { key: 'mdg', name: 'Madagascar' },
  { key: 'mwi', name: 'Malawi' },
  { key: 'mys', name: 'Malaysia' },
  { key: 'mdv', name: 'Maldives' },
  { key: 'mli', name: 'Mali' },
  { key: 'mlt', name: 'Malta' },
  { key: 'mhl', name: 'Marshall Islands' },
  { key: 'mrt', name: 'Mauritania' },
  { key: 'mus', name: 'Mauritius' },
  { key: 'mex', name: 'Mexico' },
  { key: 'fsm', name: 'Micronesia, Federated States of' },
  { key: 'mda', name: 'Moldova, Republic of' },
  { key: 'mco', name: 'Monaco' },
  { key: 'mng', name: 'Mongolia' },
  { key: 'mne', name: 'Montenegro' },
  { key: 'mar', name: 'Morocco' },
  { key: 'moz', name: 'Mozambique' },
  { key: 'mmr', name: 'Myanmar' },
  { key: 'nam', name: 'Namibia' },
  { key: 'nru', name: 'Nauru' },
  { key: 'npl', name: 'Nepal' },
  { key: 'nld', name: 'Netherlands' },
  { key: 'nzl', name: 'New Zealand' },
  { key: 'nic', name: 'Nicaragua' },
  { key: 'ner', name: 'Niger' },
  { key: 'nga', name: 'Nigeria' },
  { key: 'mkd', name: 'North Macedonia' },
  { key: 'nor', name: 'Norway' },
  { key: 'omn', name: 'Oman' },
  { key: 'pak', name: 'Pakistan' },
  { key: 'plw', name: 'Palau' },
  { key: 'pan', name: 'Panama' },
  { key: 'png', name: 'Papua New Guinea' },
  { key: 'pry', name: 'Paraguay' },
  { key: 'per', name: 'Peru' },
  { key: 'phl', name: 'Philippines' },
  { key: 'pol', name: 'Poland' },
  { key: 'prt', name: 'Portugal' },
  { key: 'qat', name: 'Qatar' },
  { key: 'rou', name: 'Romania' },
  { key: 'rus', name: 'Russian Federation' },
  { key: 'rwa', name: 'Rwanda' },
  { key: 'kna', name: 'Saint Kitts and Nevis' },
  { key: 'lca', name: 'Saint Lucia' },
  { key: 'vct', name: 'Saint Vincent and the Grenadines' },
  { key: 'wsm', name: 'Samoa' },
  { key: 'smr', name: 'San Marino' },
  { key: 'stp', name: 'Sao Tome and Principe' },
  { key: 'sau', name: 'Saudi Arabia' },
  { key: 'sen', name: 'Senegal' },
  { key: 'srb', name: 'Serbia' },
  { key: 'syc', name: 'Seychelles' },
  { key: 'sle', name: 'Sierra Leone' },
  { key: 'sgp', name: 'Singapore' },
  { key: 'svk', name: 'Slovakia' },
  { key: 'svn', name: 'Slovenia' },
  { key: 'slb', name: 'Solomon Islands' },
  { key: 'som', name: 'Somalia' },
  { key: 'zaf', name: 'South Africa' },
  { key: 'ssd', name: 'South Sudan' },
  { key: 'esp', name: 'Spain' },
  { key: 'lka', name: 'Sri Lanka' },
  { key: 'sdn', name: 'Sudan' },
  { key: 'sur', name: 'Suriname' },
  { key: 'swe', name: 'Sweden' },
  { key: 'che', name: 'Switzerland' },
  { key: 'syr', name: 'Syrian Arab Republic' },
  { key: 'tjk', name: 'Tajikistan' },
  { key: 'tza', name: 'Tanzania, United Republic of' },
  { key: 'tha', name: 'Thailand' },
  { key: 'tls', name: 'Timor-Leste' },
  { key: 'tgo', name: 'Togo' },
  { key: 'ton', name: 'Tonga' },
  { key: 'tto', name: 'Trinidad and Tobago' },
  { key: 'tun', name: 'Tunisia' },
  { key: 'tur', name: 'Türkiye' },
  { key: 'tkm', name: 'Turkmenistan' },
  { key: 'tuv', name: 'Tuvalu' },
  { key: 'uga', name: 'Uganda' },
  { key: 'ukr', name: 'Ukraine' },
  { key: 'are', name: 'United Arab Emirates' },
  { key: 'gbr', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { key: 'ury', name: 'Uruguay' },
  { key: 'uzb', name: 'Uzbekistan' },
  { key: 'vut', name: 'Vanuatu' },
  { key: 'ven', name: 'Venezuela, Bolivarian Republic of' },
  { key: 'vnm', name: 'Viet Nam' },
  { key: 'yem', name: 'Yemen' },
  { key: 'zmb', name: 'Zambia' },
  { key: 'zwe', name: 'Zimbabwe' },
];
