import { TuneOutlined } from '@mui/icons-material';
import { Checkbox, IconButton, List, ListItem } from '@mui/joy';
import { Popover } from '@mui/material';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const ColumnCustomization = () => {
  const { columns, toggleColumn } = useContactsProvider();
  const { ref, filterIsOpen, setFilterIsOpen, anchorPosition } =
    useFilterPopover<HTMLButtonElement>();

  return (
    <>
      <IconButton
        ref={ref}
        size="sm"
        sx={{
          position: 'absolute',
          right: '10px',
        }}
        onClick={() => setFilterIsOpen(!filterIsOpen)}
      >
        <TuneOutlined sx={{ fontSize: '20px' }} />
      </IconButton>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List size="sm" sx={{ padding: '20px', gap: '20px' }}>
          {columns
            .filter((item) => item.label && item.label !== 'Name')
            .map((column, columnIndex) => (
              <ListItem key={columnIndex}>
                <Checkbox
                  label={column.label}
                  checked={column.enabled}
                  onChange={() => {
                    if (column.label) {
                      toggleColumn(column.label);
                    }
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};
