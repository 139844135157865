import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';
import { useUser } from '@features/Organization/organizationSlice';

import { getContactName } from '..';
import { ContactTags } from '../ContactTags';
import { MoveContactsError } from './MoveContactsError';
import { useMoveContacts } from './useMoveContacts';

export const MoveContactsSelection = () => {
  const user = useUser();

  const {
    selected,
    actions,
    accountId,
    contacts,
    onSubmit,
    page,
    searchValue,
    setSearchValue,
    showErrorPage,
    onDone,
  } = useMoveContacts();

  const toggle = (contactId: string) => {
    if (selected.has(contactId)) {
      actions.remove(contactId);
    } else {
      actions.add(contactId);
    }
  };

  if (!page.current) {
    return null;
  }

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer sx={{ gap: '10px' }}>
          <Box>
            <Typography level="h3">Select Contacts</Typography>
            <Typography>
              Select the contacts that will move into this group.
            </Typography>
          </Box>

          <Box sx={{ paddingTop: '30px' }}>
            <Input
              variant="search"
              placeholder="Find a contact…"
              startDecorator={<Search />}
              value={searchValue}
              onChange={(event) =>
                setSearchValue(event.target.value.toLowerCase())
              }
            />
          </Box>

          <Box className="scrollable">
            <List variant="contactList">
              {contacts
                ?.filter(
                  (contact) =>
                    (!searchValue ||
                      `${contact.firstName} ${contact.lastName}`
                        .toLowerCase()
                        .includes(searchValue)) &&
                    accountId !== contact.accountId
                )
                .map((contact) => (
                  <Box key={contact.id}>
                    <ListItem
                      onClick={() => {
                        toggle(contact.id);
                      }}
                    >
                      <ListItemButton>
                        <ListItemDecorator>
                          <ContactAvatar contact={contact} />
                        </ListItemDecorator>

                        <ListItemContent>
                          <Typography>
                            {getContactName(contact, user)}
                          </Typography>
                          <ContactTags contact={contact} />
                        </ListItemContent>
                        <Checkbox checked={selected.has(contact.id)} />
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                  </Box>
                ))}
            </List>
          </Box>
          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            onClick={(event) => {
              if (!showErrorPage) {
                return onSubmit();
              }

              if (event.target instanceof HTMLElement) {
                event.target
                  .closest('ion-nav')
                  ?.push(() => <MoveContactsError />);
              }
            }}
            data-testid="move-contact-modal"
            disabled={selected.size === 0}
          >
            {showErrorPage ? 'Next' : 'Move'}
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
