import { Search } from '@mui/icons-material';
import { Box, Button, Input, Modal, Typography } from '@mui/joy';
import { useState } from 'react';

import {
  InstrumentsSelection,
  InstrumentsSelectionProps,
} from '@features/Contacts/ContactDetail/ViewContact/InstrumentsModal/InstrumentsSelection';

interface InstrumentsModalProps
  extends Omit<InstrumentsSelectionProps, 'searchValue'> {
  isOpen?: boolean;
  onClose?: () => void;
}
export const InstrumentsModal = ({
  isOpen,
  onClose,
  ...props
}: InstrumentsModalProps) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <Modal
      open={Boolean(isOpen)}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          background: 'white',
          padding: '20px',
          minHeight: '600px',
          minWidth: '650px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          position: 'relative',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography level="h3">Select instruments</Typography>
          <Typography>
            Select the instruments that this student is learning.
          </Typography>
        </Box>

        <Button
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
          onClick={onClose}
          variant="plain"
        >
          Done
        </Button>

        <Box sx={{ paddingTop: '30px' }}>
          <Input
            variant="search"
            placeholder="Find an instrument…"
            startDecorator={<Search />}
            value={searchValue}
            onChange={(event) =>
              setSearchValue(event.target.value.toLowerCase())
            }
          />
        </Box>

        <Box
          className="scrollable"
          sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
          <InstrumentsSelection searchValue={searchValue} {...props} />
        </Box>
      </Box>
    </Modal>
  );
};
