import {
  Address,
  Email,
  ParsedContact,
  Phone,
  RawContact,
} from '@shared/models/contacts';
import { addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { useCallback, useMemo, useState } from 'react';
import uuid4 from 'uuid4';

import { UploadAvatarInput } from '@components/UploadAvatar';
import { useUserData } from '@components/UserData/useUserData';
import { useScheduleSave } from '@features/Admin/Products/AddProduct/useScheduleSave';
import {
  useOrgainzation,
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { accountsCollection } from '@models/accounts/model';
import { contactsCollection } from '@models/contacts/model';
import { parseContact } from '@models/contacts/parseContact';
import { storage } from '@utils/firebase';
import { serverTimestamp } from '@utils/serverTimestamp';

import { normalizeIsPrimary } from './EditContact/EditPhones';

const defaultTags: RawContact['tags'] = {
  potentialStudent: false,
  primaryContact: false,
  student: false,
};

export const tagsMap: Record<keyof RawContact['tags'], string> = {
  potentialStudent: 'Potential student',
  primaryContact: 'Primary contact',
  student: 'Student',
  teacher: 'Teacher',
};

interface UseEditContactProps {
  contactId?: string | undefined;
  contact?: RawContact | undefined;
  isNew?: boolean;
  preset?: Partial<RawContact>;
  onDone?: (newContact?: ParsedContact) => void;
  accountId?: string;
}

export const useEditContact = ({
  contactId,
  contact,
  preset,
  isNew,
  onDone,
  accountId: presetAccountId,
}: UseEditContactProps) => {
  const organization = useOrgainzation();
  const organizationId = useOrganizationId();
  const user = useUser();
  const { userContact } = useUserData();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [notes, setNotes] = useState('');
  const [emails, setEmails] = useState<Email[]>([]);
  const [phones, setPhones] = useState<Phone[]>([]);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedInstruments, setSelectedInstruments] = useState<string[]>([]);
  const [tags, setTags] = useState<RawContact['tags']>(defaultTags);
  const [selectedAvatar, setSelectedAvatarBase] = useState<string>('mozart');
  const [avatarPreview, setAvatarPreview] = useState<string | undefined>();
  const [nextAvatar, setNextAvatar] = useState<
    { file: File; extension: string } | undefined
  >();

  const setSelectedAvatar = (input: string) => {
    setAvatarPreview(undefined);
    setSelectedAvatarBase(input);
  };

  const onAvatarUpload = useCallback(
    ({ extension, file }: UploadAvatarInput) => {
      setNextAvatar({ file, extension });
      setSelectedAvatarBase('custom');
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          const nextPreview = reader.result?.toString();

          if (!nextPreview) {
            throw new Error('No preview source');
          }

          setAvatarPreview(nextPreview);
        },
        false
      );

      reader.readAsDataURL(file);
    },
    []
  );

  const availableInstruments = useMemo(
    () => [
      ...new Set([...(userContact?.instruments || []), ...selectedInstruments]),
    ],
    [selectedInstruments, userContact]
  );

  const initialize = useCallback(() => {
    setFirstName(contact?.firstName || preset?.firstName || '');
    setLastName(contact?.lastName || preset?.lastName || '');
    setSelectedInstruments(contact?.instruments ?? preset?.instruments ?? []);
    setEmails(contact?.emails || preset?.emails || []);
    setPhones(contact?.phones || preset?.phones || []);
    setAddresses(contact?.addresses || preset?.addresses || []);
    setTags({ ...defaultTags, ...contact?.tags, ...preset?.tags });
    setSelectedAvatar(contact?.avatarSlug || preset?.avatarSlug || 'mozart');
    setNotes(contact?.notes || preset?.notes || '');
  }, [contact, preset]);

  const handleSave = useCallback(async () => {
    if (!user) {
      throw new Error('User is missing');
    }

    if (!organization) {
      throw new Error('Organization is missing');
    }

    if (!organizationId) {
      throw new Error('Organization ID is missing');
    }

    if (!userContact) {
      throw new Error('User contact not found');
    }

    const createAccount = async () => {
      const accountResult = await addDoc(accountsCollection, {
        organizationId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: user.userId,
        updatedBy: user.userId,
      });

      return accountResult.id;
    };

    const linkedAccountId = contact?.accountId || preset?.accountId;
    const accountId =
      presetAccountId || linkedAccountId || (await createAccount());

    const fileName = nextAvatar
      ? [uuid4(), nextAvatar.extension].join('.')
      : undefined;

    const uploadPromise = new Promise<void>((resolve, reject) => {
      if (!fileName || !nextAvatar) {
        return resolve();
      }

      const storageRef = ref(
        storage,
        `/organizations/${organization.id}/profiles/${fileName}`
      );
      const uploadTask = uploadBytesResumable(storageRef, nextAvatar.file);

      const deleteOld = (async () => {
        if (contact?.avatar) {
          await deleteObject(
            ref(
              storage,
              `/organizations/${organization.id}/profiles/${contact.avatar}`
            )
          );
        }
      })();

      uploadTask.on('state_changed', undefined, reject, async () => {
        await deleteOld;

        resolve();
      });
    });

    const nextContact: RawContact = {
      createdAt: contact?.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: contact?.createdBy || user?.userId,
      updatedBy: user?.userId,
      firstName,
      lastName,
      emails: normalizeIsPrimary(
        emails.filter((email) => email.address.length)
      ),
      phones: normalizeIsPrimary(phones.filter((phone) => phone.number.length)),
      addresses: addresses.filter((address) =>
        Object.entries(address).some(([key, value]) => key !== 'type' && value)
      ),
      organizationId,
      accountId,
      instruments: [...selectedInstruments],
      tags: {
        ...tags,
        student: selectedInstruments.length > 0,
        primaryContact: isNew && !linkedAccountId ? true : tags?.primaryContact,
      },
      avatarSlug: selectedAvatar,
      notes,
      ...(fileName && { avatar: fileName }),
    };

    const userContactInstruements = [
      ...new Set([...(userContact.instruments || []), ...selectedInstruments]),
    ];

    if (
      userContact.tags.teacher &&
      userContactInstruements.length !== userContact.instruments?.length
    ) {
      const nextUserContact: Partial<RawContact> = {
        updatedAt: serverTimestamp(),
        updatedBy: userContact.userId,
        instruments: userContactInstruements,
      };

      await updateDoc(doc(contactsCollection, userContact.id), nextUserContact);
    }

    if (isNew) {
      const nextId = await (async () => {
        try {
          const result = await addDoc(contactsCollection, nextContact);

          return result.id;
        } catch (rawError) {
          const errorMessage =
            rawError && typeof rawError === 'object' && 'message' in rawError
              ? String(rawError.message)
              : undefined;

          // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
          if (errorMessage?.includes('Document already exists')) {
            const errorMessageId = errorMessage.split('/').at(-1);

            if (errorMessageId) {
              console.log(`Recovering from a silly error (${errorMessageId})…`);

              return errorMessageId;
            }
          }

          console.error('Failed to add new contact', rawError);
          console.error('Error message', errorMessage);

          throw rawError;
        }
      })();
      const nextContactDoc = await getDoc(doc(contactsCollection, nextId));
      await uploadPromise;
      onDone?.(parseContact(nextContactDoc));
    } else {
      if (!contactId) {
        throw new Error('Contact ID is missing');
      }

      await updateDoc(doc(contactsCollection, contactId), nextContact);
      await uploadPromise;
      onDone?.();
    }

    initialize();
  }, [
    firstName,
    lastName,
    organizationId,
    isNew,
    contactId,
    onDone,
    contact,
    initialize,
    preset,
    emails,
    phones,
    addresses,
    presetAccountId,
    tags,
    user,
    selectedInstruments,
    userContact,
    selectedAvatar,
    notes,
    nextAvatar,
    organization,
  ]);

  const [scheduleSave] = useScheduleSave(handleSave);

  return {
    initialize,
    scheduleSave,
    fieldProps: {
      firstName,
      setFirstName,
      lastName,
      setLastName,
      emails,
      setEmails,
      phones,
      setPhones,
      addresses,
      setAddresses,
      tags,
      setTags,
      availableInstruments,
      selectedInstruments,
      setSelectedInstruments,
      selectedAvatar,
      setSelectedAvatar,
      notes,
      setNotes,
      onAvatarUpload,
      avatarPreview,
      nextAvatar,
    },
  };
};

type UseEditContact = typeof useEditContact;
export type UseEditContactValue = ReturnType<UseEditContact>;
export type EditContactFieldProps = UseEditContactValue['fieldProps'];
