import { Settings } from '@mui/icons-material';
import Badge, { BadgeProps } from '@mui/joy/Badge';

interface AvatarBadgeProps extends BadgeProps {
  isPrimary?: boolean;
  iconSize?: number;
}

const sizeMap: Record<NonNullable<AvatarBadgeProps['size']>, number> = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
};

export const AvatarBadge = ({
  isPrimary,
  size = 'md',
  ...props
}: AvatarBadgeProps) => {
  const iconSize = sizeMap[size];

  return (
    <Badge
      badgeContent={
        isPrimary ? (
          <Settings
            sx={{
              width: iconSize,
              height: iconSize,
            }}
          />
        ) : (
          0
        )
      }
      sx={(theme) => ({
        '& .MuiBadge-badge': {
          padding: 0,
          backgroundColor: theme.palette.neutral[400],
        },
      })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeInset="14%"
      size={size}
      {...props}
    />
  );
};
